import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
//import ProgramCalendar from "../components/ProgramCalendar";
//import CalendarCustomView from "../components/CalendarCustomView";
export default function Program(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "2000px"}}>
            <h1><strong>Programme</strong></h1>


            <p style={{fontSize: "20px"}}>
                <NavLink to="/program/technical-program">
                Detailed Technical Programme
                </NavLink>
            </p>
            <p style={{fontSize: "20px"}}>
                <NavLink to="/program/keynotes">
                Keynotes
                </NavLink>
            </p>
            <p style={{fontSize: "20px"}}>
                <NavLink to="/program/invited-speakers">
                Invited Speakers
                </NavLink>
            </p>
            <p style={{fontSize: "20px"}}>
                <NavLink to="/program/workshops">
                Workshops
                </NavLink>
            </p> 
            <p style={{fontSize: "20px"}}>
                <NavLink to="/program/tutorials">
                Tutorials
                </NavLink>
            </p>

            <h2 style={{ textAlign: "center" }}><strong>Programme at a glance</strong></h2>
            <img src="/programme_at_a_glance.png" alt="Programme at a glance" style={{ display: "block", margin: "auto", width: "100%"}} />
            <p>
            <b>Note:</b> Registration will be open every day starting at 8:00 on Monday and at 8:30 the rest of the days, until the afternoon coffee break.
            </p>
        </Box>
    )
    /*
    return(
        <Box sx={{maxWidth: "90%", margin:"auto"}}>
            <h2>Program</h2>
            <p>

            </p>
            <CalendarCustomView/>
        </Box>
    )
    */


}
