import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function Tutorials(){

    const location = useLocation();
    
    const refs = [
        useRef(null), useRef(null), useRef(null), useRef(null)
    ]

    const ids = ["zhuge", "contreras", "bacco"];

    const scrollToElement = (refIndex) => {
        const {current} = refs[refIndex];   
        if (current !== null){
            current.scrollIntoView({behavior: "smooth"})
        }
    }

    useEffect(() => {
        if(location.hash !== ""){
            let hash = location.hash.split("#")[1]
            if(ids.includes(hash)){
                let index = ids.indexOf(hash)
                scrollToElement(index)
                setTimeout(() => {
                    scrollToElement(index)
                }, 500);
            }
        }
        // eslint-disable-next-line
    }, [location]);

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "200px"}}>
            <h1><strong>Tutorials</strong></h1>
            <Tutorial
                author="Qunbi Zhuge"
                tutorial_id="zhuge"
                refs={refs[0]}
                title="Autonomous driving of optical networks: from digital twin modeling to intelligent network control"
                affiliation="Department of Electronic Engineering, Shanghai Jiao Tong University"
                abstract="Driven by the ever-increasing capacity demand, the scale of optical networks expands rapidly, aided by new technologies including multi-band transmission and high-degree
                reconfigurable optical add-drop multiplexer (ROADM). The physical layer of optical networks involves complex linear and nonlinear fiber channel effects, imposing
                challenges on the management and control of large-scale optical networks. In this tutorial, we will first describe progress and challenges in building and implementing
                digital twin models to precisely characterize physical layer impairments of deployed optical systems. Then, based on the digital twin models, the path to achieve
                autonomous driving of optical networks for more capacity and high reliability will be discussed. Artificial intelligence will play a key role in this new paradigm. Finally, some
                use cases on intelligent network control will be presented."
                photo="/portraits/Zhuge.jpg"
            />

            <Tutorial
                author="Luis M. Contreras"
                tutorial_id="contreras"
                refs={refs[1]}
                title="Transport Slicing from the perspective of the IETF"
                affiliation="Technology Expert, Telefónica Innovación Digital"
                abstract="The IETF is defining a technology-agnostic approach for defining slices in the transport network. With the introduction of SDN capabilities into production networks, transport network slicing is the next step in the portfolio of automatized services to be offered to customers, either internal or external. This tutorial presents the steps done by Telefonica in that direction and the contributions to the definition of a standard-based approach for exploiting network programmability and automation in the provision of network slices in transport.
                Network operators are in the transition of incorporating SDN capabilities into production networks. Despite there have been intensive research in the area, now it is time of defining ways of making SDN operational and sustainable in the context of service provider’s networks. This tutorial presents the steps done by Telefonica in that direction, then presenting real experiences and directions taken from an operator perspective."
                photo="/portraits/Contreras.jpeg"
            />
            
            <Tutorial
                author="Davide Bacco"
                tutorial_id="bacco"
                refs={refs[2]}
                title="From Quantum Cryptography to the Quantum Internet"
                affiliation="Associate Professor University of Florence, co-founder Q.T.I srl"
                abstract="In a society based on the continuous exchange of sensitive data and information, the importance of secure and trustable communications is essential. By exploiting principles of Quantum Physics, it is possible to share data in an unconditionally secure way, no longer based on mathematical assumptions of the encryption algorithm, but founded on the basic principles of Quantum Mechanics. In this context, my research relies on the development of a Quantum Communication (QCs) system able to increase the actual performance in terms of rate, security, and distance independently from the transmission medium.  In this tutorial, I will explain the basic concept of quantum key distribution (QKD) protocols and focus on the current benefits and challenges of this technology in real-world communication networks. In addition, I will explain how QKD is the first step towards the Quantum Internet. The Quantum Internet is a network of quantum computers and devices that will someday send, compute, and receive information encoded in quantum states. The Quantum Internet will allow completely new applications. Examples are ultra-precise clock synchronization, new ways to secure elections, blind quantum computation, and many others."
                photo="/portraits/Davide.jpg"
            />
        </Box>
    )
}


function Tutorial({author, affiliation, title, abstract, photo, tutorial_id, refs}){
    return(
        <div style={{marginTop: 25}} id={tutorial_id} ref={refs}>

            <Grid container>

                <Grid item xs={3}>
                <img src={photo} alt={`${author}'s portait`} style={{width: "90%", margin: "auto", borderRadius: 5, marginTop: 30}}/>
                </Grid>
                <Grid item xs={9}>
            <h2>{title}</h2>
                <div style={{marginTop: 10}}>
                    <b>{author}</b>, {affiliation}
                </div>
                <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' }}}>
                <div style={{textAlign: "justify", marginTop: 18}}>
                    {abstract}
                </div>
                </Grid>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item md={9} xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                <div style={{textAlign: "justify", marginTop: 12}}>
                    {abstract}
                </div>
                </Grid>
            </Grid>
        </div>

    )
}