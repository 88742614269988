import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function ImageSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };
  return (
    <Slider {...settings}>
     {/*
     <div style={{margin: "auto", width:"100%"}}>
        <img src="1.jpg" alt="Madrid 1" width={"100%"}></img>
      </div>
     */} 
      <div style={{margin: "auto",width:"100%"}}>
      <img src="/2.jpg" alt="Madrid 2" width={"100%"}></img>
      </div>
      {/*<div style={{margin: "auto",width:"100%"}}>
      <img src="3.svg" alt="Madrid 3" width={"100%"} ></img>
      </div>
      <div style={{margin: "auto",width:"100%"}}>
      <img src="4.svg" alt="Madrid 4" width={"100%"}  ></img>
      </div>*/}
    </Slider>
  );
}