import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Iframe } from '../components/Iframe'

export default function SocialEvents(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "2000px"}}>
            <h1><strong>Social Events</strong></h1>

            During the conference, we have organized several social events to allow attendees to network and enjoy the city of Madrid.

            <h2>Welcome Reception Cocktail-Dinner (Monday 20:15)</h2>

            <p>
            The conference Welcome Reception will take place at La Favorita Madrid (entrance garden and ground floor), where a tapas & drinks (cocktail-style) dinner will be served, entertained with live music moments.
            </p>
            <p>
            Calle de Covarrubias, 25, Chamberí, 28010 Madrid.
            </p>

            <p>
            From the conference venue you can take the metro (Line 5, 6 Stops, Alonso Martínez Station) or a 50 minute walk. If you want to take the metro, we recommend you to select the 10-trip ticket (zone A), which can be bought in the metro stations.
            </p>
            <p>
            <a href="https://maps.app.goo.gl/VhRAdEzSAmHcPojy8">Google Maps recommended walking path Link</a>
            </p>

            <Iframe src="https://www.google.com/maps/embed?pb=!1m24!1m8!1m3!1d12150.078316506868!2d-3.7029769274169917!3d40.419492456490694!3m2!1i1024!2i768!4f13.1!4m13!3e2!4m5!1s0xd4227d0c7b4d94d%3A0x54bc446f4fa2e7d8!2sPuerta%20de%20Toledo%2C%20Gta.%20de%20la%20Prta%20de%20Toledo%2C%20s%2Fn%2C%20Centro%2C%2028005%20Madrid!3m2!1d40.4067666!2d-3.7116135!4m5!1s0xd4228899346800b%3A0xcc84786b9ce25a57!2sLa%20Favorita%20Madrid%2C%20Calle%20de%20Covarrubias%2C%20Madrid!3m2!1d40.430643499999995!2d-3.6982025999999997!5e0!3m2!1sen!2ses!4v1713435685193!5m2!1sen!2ses" width="100%" height="500" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>


            <h2>Guided Tour Through Madrid Historic Center (Tuesday 19:30)</h2>

            <p>
            The tour will start at the conference venue (Puerta de Toledo) and will take you through the most emblematic places of Madrid's historic center.
            </p>
            
            <h2>Gala Dinner (Wednesday 20:15)</h2>

            <p>
                The Gala Dinner will take place in <a href="https://laterrazadelsantodomingo.es/en">La Terraza de Santo Domingo</a>. The venue is located in the heart of Madrid. 
            </p>

            <p>
                From the conference venue, you could either walk (26 minutes) or take the metro (Line 5, 3 stops, Callao Station).
            </p>

            <p>
                <a href="https://maps.app.goo.gl/qDXeJePtFV8Zt8699">Google Maps recommended walking path link</a>
            </p>

            <Iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d6075.573328769477!2d-3.71035651889096!3d40.413576610571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e2!4m5!1s0xd4227d0c7b4d94d%3A0x54bc446f4fa2e7d8!2sPuerta%20de%20Toledo%2C%20Gta.%20de%20la%20Prta%20de%20Toledo%2C%20s%2Fn%2C%20Centro%2C%2028005%20Madrid!3m2!1d40.4067666!2d-3.7116135!4m5!1s0xd42287b7bc9f6db%3A0xdca721d29deff8fd!2sLa%20Terraza%20del%20Santo%20Domingo%2C%20Restaurante%20Terraza%2C%20Calle%20de%20San%20Bernardo%2C%20Madrid!3m2!1d40.4206046!2d-3.7086064999999997!5e0!3m2!1sen!2ses!4v1713435065739!5m2!1sen!2ses" width="100%" height="500" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>



            <h1><strong>Communications</strong></h1>

            <p>
            We have created a Telegram channel for more direct communication with the attendees. You can join the channel by clicking <a href="https://t.me/+Bd3EAVR816I1ZGZk">here</a>.
            </p>

            We have also created a group for PhD students which is available within the channel. 
        </Box>
    )
}
