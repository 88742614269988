import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export default function Program(){



    return(
        <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "2000px"}}>
            <h1 style={{ textAlign: "center" }}><strong>Technical Programme</strong></h1>
            <p style={{ textAlign: "center" }}>  <a href="/ONDM2024_Technical_Program_v3.pdf" download>Full Technical Programme as PDF</a> </p>
            <h4 style={{ textAlign: "center" }}><strong>Programme at a glance</strong></h4>
            <img src="/programme_at_a_glance.png" alt="Programme at a glance" style={{ display: "block", margin: "auto", width: "100%"}} />
            <p>
            <b>Note:</b> Registration will be open every day starting at 8:00 on Monday and at 8:30 the rest of the days, until the afternoon coffee break.
            </p>
            {/*
                MONDAY
            */}
            <ConferenceDay day="Monday, May 6"/>

            <SessionDetails 
                title="WELCOME AND OPENING"
                time="9:00 - 9:10"
                chairTitleCustom="ONDM 2024 General Chair"
                chair="David Larrabeiti (Univ. Carlos III de Madrid)"
                otherChairs = {[{
                    chairTitleCustom: "ONDM 2024 TPC-Chairs",
                    chair: "Luca Valcarenghi, Carmen Mas-Machuca, Jose A. Hernández"},
                    {chairTitleCustom: "ONDM StC Chair",
                    chair: "Lena Wosinka (Chalmers University of Technology)"},
                    {chairTitleCustom: "UC3M Vice-Provost of Research",
                    chair: "Luis Enrique García-Muñoz (Univ. Carlos III de Madrid)"
                }]}
            />

            <OtherDetails 
                title="Next Generation Coherent Optical Pluggables: Challenges and Guidelines for Telco Networks"
                time="9:10 - 10:10"
                type="KEYNOTE"
                author="Juan Pedro Fernández Palacios (Telefonica CTIO)"
                abstract="/program/keynotes#fernandez"
                chair="Michela Svaluto Moreolo (CTTC)"
            />

            <OtherDetails 
                title="The driving force and technology challenges for the optical network 2025-2030"
                time="10:10 - 10:30"
                type="INVITED TALK PLATINUM SPONSOR HUAWEI"
                author="Liang Zhang"
                chair="Michela Svaluto Moreolo (CTTC)"
            /> 
            <ConferenceEvent time="10:30 - 11:00" title="COFFEE BREAK" color="#57B732"/>

            <SessionDetails
                title="WDM SYSTEMS AND TRANSMISSION"
                time="11:00 - 13:00"
                number="1"
                chair="Michela Svaluto Moreolo (CTTC)"
            />
                <SessionItem
                    title="Design of Optical Transmission Link with Cascaded Fibre-Optical Parametric Amplifiers"
                    time="11:00 - 11:15"
                    author="Long Hoang Nguyen; Sonia Boscolo; Stylianos Sygletos"
                />
                <SessionItem
                    title="Implications of Deploying Optical Phase Conjugators (OPCs) to Reduce DSP Overhead in a Dynamic WDM Coherent Optical Network"
                    time="11:15 - 11:35"
                    author="Boyang Hu; Mrudula Krishna; Deepa Venkitesh; Byrav Ramamurthy"
                />
                <SessionItem
                    title="Linear and Nonlinear SNR Estimation Using Spectral and Temporal Correlations"
                    time="11:35 - 11:55"
                    author="Vinod Bajaj; Petros Ramantanis; Fabien Boitier; Patricia Layec"
                />
                <SessionItem
                    title="Scalable Multilevel oDAC-Based PAM-m | QAM-m2 Transmitter Using Just PAM-2|4 Electronic Drivers -Comparative Performance"
                    time="11:55 - 12:10"
                    author="Konstantinos Moschopoulos; Moshe Nazarathy; Ioannis Tomkos"
                />
                <SessionItem
                    title="Improving Spectrum Efficiency with Adaptive Regenerations in Elastic Optical Networks"
                    time="12:10 - 12:30"
                    author="Saquib Amjad; Carmen Mas-Machuca"
                />
                <SessionItem
                    title="Does Considering Only Crosstalk Suffice for QoT-Aware Provisioning in Multicore Fiber Networks?"
                    time="12:30 - 12:45"
                    author="Jaya Lakshmi Ravipudi; Maite Brandt-Pearce"
                />
                <SessionItem
                    title="Design of Time-Domain Learned Volterra Equalisers for WDM Systems"
                    time="12:45 - 13:00"
                    author="Nelson Castro; Sonia Boscolo; Andrew Ellis; Stylianos Sygletos"
                />


            <ConferenceEvent time="13:00 - 14:00" title="LUNCH" color="orange"/>


            <OtherDetails 
                title="Autonomous driving of optical networks: from digital twin modeling to intelligent network control"
                time="14:00 - 15:00"
                type="TUTORIAL"
                author="Qunbi Zhuge (Shanghai Jiao Tou Univ., PRC)"
                abstract="/program/tutorials#zhuge"
                chair="Byrav Ramamurthy"
            />

            <SessionDetails 
                title="DIGITAL TWINS IN OPTICAL NETWORKS"
                number="2" 
                time="15:00 - 16:10"
                chair="Byrav Ramamurthy"
            />

                <SessionItem
                    title="Deep Learning Gain and Tilt Adaptive Digital Twin Modeling of Optical Line Systems for Accurate OSNR Predictions"
                    time="15:00 - 15:15"
                    author="Rocco D'Ingillo; Andrea D'Amico; Renato Ambrosone; Emanuele Virgillito; Vittorio Gatto; Stefano Straullu; Francesco Aquilino; Vittorio Curri"
                />
                <SessionItem
                    title="Using the OCATA Digital Twin to Improve QoT of Optical Connections in Multiband Optical Networks"
                    time="15:15 - 15:30"
                    author="Sadegh Ghasrizadeh Dezfouli; Prasunika Khare; Marc Ruiz; Luis Velasco"
                />
                <SessionItem
                    title="Field Trial Demonstration of Digital Twin Assisted Network Optimization on a Production Network"
                    time="15:30 - 15:45"
                    author="Reda Ayassi; Xin Yang; Yvan Pointurier; Gabriel Charlet; Quoc Thai Nguyen; Yassine El Harraz"
                />
                <SessionItem
                    title="Bottom-Up Design of Digital Twins for Optical Transport Networks: Challenges and Lessons Learned"
                    time="15:45 - 16:10"
                    author="Renato Cherini; Nehuen Gonzalez-Montoro; Luis Compagnucci; Jorge M Finochietto"
                />

            <ConferenceEvent time="16:10 - 16:40" title="COFFEE BREAK" color="#57B732"/>


            <OtherDetails 
                title="The implementation of machine intelligence in next-generation access networks— rationale, challenges and emerging solutions"
                time="16:40 - 17:40"
                type="KEYNOTE"
                author="Elaine Wong (Univ. of Melbourne)"
                abstract="/program/keynotes#wong"
                chair={"Raul Muñoz"}
            />

            <OtherDetails 
                title="Sensing in optical networks: from raw monitoring data to advanced failure analysis and beyond"
                time="17:40 - 17:55"
                type="INVITED TALK GOLD SPONSOR NOKIA"
                author="Camille Delezoide (Nokia Bell Labs)"
                chair={"Raul Muñoz"}
            /> 

            <SessionDetails 
                title="TOWARDS 6G: NETWORKING TECHNOLOGIES FOR NEXT-GENERATION MOBILE COMMUNICATION SYSTEMS"
                number="3" 
                time="17:55 - 19:05"
                chair={"Raul Muñoz"}
            />
                <SessionItem
                    title="Next-Gen Green Networking: Fusing Power-over-Fiber and Solar Harvesting in 6G C-RAN Scenarios"
                    time="17:55 - 18:15"
                    author="Francisco-Javier Moreno-Muro; Gabriel Otero Pérez; Enrique Fernandez Sanchez; David Larrabeiti; Juan Pedro Fernández-Palacios; Pablo Pavon-Marino; Carmen Vazquez"
                />
                <SessionItem
                    title="Experimental Demonstration of High Precision Time Transfer in FPGA for 5G and 6G Networks"
                    time="18:15 - 18:35"
                    author="Lakshmi Rajagopal; Romerson D Oliveira; Andrew Lord; Yeshpal Singh; Reza Nejabati; Dimitra Simeonidou"
                />
                <SessionItem
                    title="Validation of Digital Subcarriers Coherent Transceivers for Mobile Transport Applications in 5G Radio Access Networks"
                    time="18:35 - 18:50"
                    author="Pablo Torres-Ferrera; Roberto Mercinelli; Marco Caretti; Emilio Riccardi; Maurizio Valvo; Per Lembre; Johan Bäck; Alexandre Afonso; Neil Jackson; Paul Norris; Mario Porrega; Giuliano Benetazzo; David Hillerkuss; Antonio Napoli; Annachiara Pagano"
                />
                <SessionItem
                    title="Feasibility Study of Nyquist-Switching Node to Enable Mesh RU Fronthaul Interconnections and Flexible 6G Mobile Network Operation"
                    time="18:50 - 19:05"
                    author="Christos Christofidis; Georgios Gorgias; Haris Georgopoulos; Antonio Napoli; D. Marom; Ioannis Tomkos"
                />


            <ConferenceEvent time="20:15 - on" title="WELCOME RECEPTION COCKTAIL-DINNER" color="#27CAD4"/>


            {/*
                TUESDAY
            */}
            <ConferenceDay day="Tuesday, May 7"/>

            <OtherDetails 
                title="6G and Onward to Next G: From Society 5.0 to Symbiotic INTERBEING"
                time="9:00 -10:00"
                type="KEYNOTE"
                author="Martin Maier (INRS, Canada)"
                abstract="/program/keynotes#maier"
                chair="Lena Wosinska"
            />
            
            <OtherDetails 
                title="Automation of open and disaggregated optical transport networks – Challenges and research highlights for the emerging AI-driven network"
                time="10:00 - 10:15"
                type="INVITED TALK GOLD SPONSOR ADTRAN"
                author="Achim Autenrieth, Adtran, Germany. Co-authors: Jasper Müller, Vignesh Karunakaran"
                chair="Lena Wosinska"
            /> 
            <ConferenceEvent time="10.15 - 10.45" title="COFFEE BREAK" color="#57B732"/>

            <WorkshopDetails
                workshopNumber={1}
                time="10:45 - 12:45"
                titleA="End-to-end network for 2030 and beyond: from F5G-Advanded to F6G"
                titleB="Data exploitation over the 6G edge/cloud continuum"
                organizersA="Yvan Pointurier (Huawei, France), Raul Muñoz (CTTC, Spain)"
                organizersB="Carlos Jesús Bernados Cano (Univ. Carlos III of Madrid, Spain), Antonio de la Oliva (Univ. Carlos III of Madrid, Spain)"
                speakersA={"Juan Pedro Fernandez-Palacios Gimenez (Telefonica), Ligia Maria Moreira Zorello (Meta), Jörg-Peter Elbers (Adtran), Sébastien Bigo (Nokia), Liang Zhang (Huawei), Ricard Vilalta (CTTC)"}
                speakersB="Aitor Zabala Orive (Telcaria), Ernesto Correa Velandia (Capgemini), Marc Molla Rosello (Ericsson), Carlos Jesús Bernados Cano (Univ. Carlos III of Madrid), Ignacio Labrador Pavon (Eviden), Antonio de la Oliva (Univ. Carlos III of Madrid)"
                abstractA={"/program/workshops#wsEndToEnd"}
                abstractB={"/program/workshops#wsDataExploitation"}
                roomA="Salón de Grados"
                roomB="-1.A01 (basement)"
            />

            <ConferenceEvent time="12.45 - 13.45" title="LUNCH (and Poster exhibition without authors)" color="orange"/>


            <WorkshopDetails
                workshopNumber={2}
                time="13:45 - 15:45"
                titleA="Applying Machine Learning to Optical Networks in Real-World Production Environments"
                titleB="Security in Classical Optical Communications and Quantum Technologies"
                organizersA="Marija Furdek Prekratic (Chalmers University of Technology), Stephan Pachnicke (Kiel Univ.), Achim Autenrieth (ADTRAN)"
                organizersB="Luis Velasco (Universidad Politécnica de Catalunya, Spain), Roberto Proietti (PoliTo, Italy)"
                speakersA={"Sai Patri (ADTRAN), Lars Kruse (Kiel Univ.), Jasper Müller (ADTRAN), Christina Rottondi (Politecnico di Torino), Alejandra Beghelli (Univ. College London), Behnam Shariati (Fraunhofer HHI)"}
                speakersB="Ramon Casellas (CTTC), Idelfonso Tafur (Technical Univ. of Eindhoven), Vicente Martin (Universidad Politécnica de Madrid), Antonio Pastor (Telefonica), Vincent Lefebvre (Tages Solidshield), Luca Poti (CNIT)"
                abstractA={"/program/workshops#wsMachineLearning"}
                abstractB={"/program/workshops#wsSecurity"}
                roomA="Salón de Grados"
                roomB="-1.A01 (basement)"
            />

            <ConferenceEvent time="15:45 - 17:00" 
                title="POSTER SESSION w/ coffee (Poster exhibition with presentation by the authors)" color="#57B732"
                chair="Emanuele Virgillito"
            />
            
            <p><b>Routing Strategies for Quantum Key Distribution Networks Based on Trusted Relay Nodes</b>
               <br/>Tommy van Duijn; Sebastian R. Verschoor; Simon Rommel; Idelfonso Tafur Monroy</p>

            <p><b>Multi-Layered Optical Network Architecture with Coarse-Granularity-Routing Optical Bypass</b>
               <br/>Hayato Yuasa; Kenji Cruzado; Yojiro Mori; Shih-Chun Lin; Motoharu Matsuura; Suresh Subramaniam; Kohei Hosokawa; Shigeyuki Yanagimachi; Hiroshi Hasegawa</p>

            <p><b>Multi-Layered Optical Network Architecture with Coarse-Granularity-Routing Optical Bypass</b>
               <br/>Hayato Yuasa; Kenji Cruzado; Yojiro Mori; Shih-Chun Lin; Motoharu Matsuura; Suresh Subramaniam; Kohei Hosokawa; Shigeyuki Yanagimachi; Hiroshi Hasegawa</p>

            <p><b>Scalable and Quantum Resilient Heterogeneous Edge Computing Enabling Trustworthy AI (SMARTY)</b>
               <br/>Juan José Vegas Olmos; Filippo Cugini; Pedro Reviriego</p>

            <p><b>Experimental Evaluation of Secure Digital Twin Model Exchange Using Distributed Ledger Technologies</b>
               <br/>Pol Gonzalez; Marc Ruiz; Luis Velasco</p>

            <p><b>On Optimizing Inband Telemetry Systems for Accurate Latency-Based Service Deployments</b>
               <br/>Nataliia Koneva; Alfonso Sánchez-Macián; José Alberto Hernández; Oscar González de Dios</p>

            <p><b>Reinforcement-Learning Based Routing for Packet-Optical Networks with Hybrid Telemetry</b>
               <br/>Alejandro Leonardo García Navarro; Nataliia Koneva; Alfonso Sánchez-Macián; José Alberto Hernández; Oscar González de Dios; José Manuel Rivas Moscoso</p>

            <p><b>Provisioning & Restorability in Multi Fiber Optical Networks With/Without Spatial Lane Switching</b>
               <br/>Ramon Casellas; Raul Muñoz; Ricardo Martinez; Ricard Vilalta; Josep M. Fabrega; Yvan Pointurier</p>

            <p><b>Enhancing Network Performance and Reducing Power Consumption in Elastic Optical Networks with Deep Reinforcement Learning</b>
               <br/>Ricardo Martinez; Carlos Hernández-Chulde; Ramon Casellas; Ricard Vilalta; Raul Muñoz; Oscar González de Dios; Juan Pedro Fernández-Palacios</p>

            <p><b>Single Open Programmable Packetponder Element for Access/Metro Aggregation and Network Consolidation</b>
               <br/>Julie Raulin; Jim Zou; Scott Hill; John Griffiths; Gawen Davey; Alexander Jeffries; Ian Cooper; Paul Gunning; Cormac J. Sreenan; Fatima C Garcia-Gunning</p>

            <p><b>Design for In-Network Caching Assisted Quantum-Secured Networking</b>
               <br/>Chankyun Lee; Wonhyuk Lee</p>

            <p><b>Dilemma for Multi-Band Network Migration: Single-Band or Multi-Band Transceivers</b>
               <br/>Soheil Hosseini; Ignacio de Miguel; Oscar González de Dios; Juan Pedro Fernández-Palacios; Ramón J. Durán Barroso</p>

            <p><b>Impact of Laser Communication Terminals in Optical Satellite Networks</b>
               <br/>Arantxa Villavicencio Paz; Mario Wenning; Nicolas Perlot; Achim Autenrieth</p>

            <p><b>Explainable Artificial Intelligence-Guided Optimization of ML-Based Traffic Prediction</b>
               <br/>Aleksandra Knapinska; Omran Ayoub; Cristina E. M. Rottondi; Piotr Lechowicz; Krzysztof Walkowiak</p>

            <p><b>FSO-Based Reconfigurable Optical Networks: Source Routing for Decoupling Multilayer TE</b>
               <br/>Everson Scherrer Borges; Moises R. N. Ribeiro; Rafael Silva Guimaraes; Bruno Missi Xavier; Pedro P Pecolo; Cristina Klippel Dominicini; Magnos Martinello; Marco Ruffini</p>

            <p><b>Liquid Neural Network-Based Adaptive Learning vs. Incremental Learning for Link Load Prediction amid Concept Drift Due to Network Failures</b>
               <br/>Omran Ayoub; Davide Andreoletti; Aleksandra Knapinska; Róża Goścień; Piotr Lechowicz; Tiziano Leidi; Silvia Giordano; Cristina E. M. Rottondi; Krzysztof Walkowiak</p>

            <p><b>Count-Min Sketches for Telemetry: Analysis of Performance in P4 Implementations</b>
               <br/>José Alberto Hernández; Davide Scano; Filippo Cugini; Gonzalo Martínez; Nataliia Koneva; Alfonso Sánchez-Macián; Oscar González de Dios</p>

            <WorkshopDetails
                workshopNumber={3}
                time="17:00 - 19:00"
                titleA="Optical networks roadmap: chart out applications and needs, Machine Learning in optical networks"
                titleB="Physical Layer Security: Just snake oil or quantifiable security?"
                organizersA="Francesco Musumeci (Politecnico di Milano), Xiaoliang Chen (Sun Yat-Sen Univ.), Daniel Kilper (Trinity College Dublin), Lena Wosinska (Chalmers Univ. of Technology), Zuqing Zhu (Univ. of Science and Technology of China)"
                organizersB="Helmut Grießer (ADVA, Germany), Stefan Köpsell (TU Dresden / Barkhausen Institute, Germany), Christoph Lipps (DFKI, Germany)"
                speakersA={"Yvan Pointurier (Huawei), Luis Velasco (Universitat Politècnica de Catalunya), Shuangyi Yan (Univ. of Bristol), Nicola Sambo (Scuola Superiore Sant’Anna), Antonio Napoli (Infinera), Dan Kilper (Trinity College Dublin)"}
                speakersB="Momtchil Peev (Huawei), Konrad Banaszek (Univ. of Warsaw), Dan Sadot (Ben Gurion Univ. and CyberRidge), Kai Jansen (PHYSEC), Werner Teich (University Ulm)"
                abstractA={"/program/workshops#wsMachineLearning"}
                abstractB={"/program/workshops#wsSecurity"}
                roomA="Salón de Grados"
                roomB="-1.A01 (basement)"
            />

            <ConferenceEvent time="19:30 - 21:30" title="GUIDED TOUR THROUGH MADRID" color="#27CAD4"/>


            {/*
                Wednesday
            */}
            <ConferenceDay day="Wednesday, May 8"/>

            <OtherDetails 
                title="Optical Technologies for Terabit Networking"
                time="9:00 - 10:15"
                type="KEYNOTE"
                author="Geoff Bennett (Infinera UK)"
                abstract="/program/keynotes#bennett"
                chair="Suresh Subramaniam (George Washington University)"
            />

            <SessionDetails
                title="OPTICAL ACCESS NETWORKS"
                time="10:15 - 10:55"
                number="4"
                chair="Suresh Subramaniam (George Washington University)"
            />
                <SessionItem
                    title="Enabling Extended Access with Light-Trees and Point-To-Multipoint Coherent Transceivers"
                    time="10:15 - 10:30"
                    author="Konstantinos Yiannopoulos; Polyzois Soumplis; Konstantinos Christodoulopoulos; Panagiotis Kokkinos; Antonio Napoli; Mohammad Hosseini; Emmanouel Varvarigos"
                />
                <SessionItem
                    title="Dynamic Spatial Aggregation for Energy-Efficient Passive Optical Networks"
                    time="10:30 - 10:55"
                    author="Andrea Marotta; Carlo Centofanti; Dajana Cassioli; Fabio Graziosi; Annachiara Pagano; Nicola Sambo; Luca Valcarenghi"
                />

            <ConferenceEvent time="10:55 - 11:25" title="COFFEE BREAK" color="#57B732"/>

            <SessionDetails 
                title="WDM SYSTEMS AND TRANSMISSION"
                number="5" 
                time="11:25 -13:10"
                chair="Hiroshi Hasegawa (Nagoya University)"
            />

                <SessionItem
                    title="Traversing Petabit-Scale Networks: Empowering Generative AI Workloads"
                    time="11:25 - 11:50"
                    author="Arash Vakili (Meta, USA)"
                />
                <SessionItem
                    title="Performance Assessment of Dynamic Multiband OADM in Metro-Access Network with 100Gbps/λ PAM4"
                    time="11:50 - 12:05"
                    author="Shiyi Xia; Marijn Rombouts; Zhouyi Hu; Henrique Santana; Elham Khani; Nicola Calabretta"
                />
                <SessionItem
                    title="OSNR-Based Hardware Optimization of a Filterless Point-To-Multipoint Network Using Digital Subcarrier Multiplexing"
                    time="12:05 - 12:25"
                    author="Carlos Castro; Antonio Napoli; Marco Quagliotti; Joao Pedro; Mario Porrega; Emilio Riccardi"
                />
                <SessionItem
                    title="Experimental Analysis and Modeling of Single-Sided Vs Dual-Sided Filtering in Flex-Grid Switched Optical Networks"
                    time="12:25 - 12:45"
                    author="Emanuele Virgillito; Stefano Straullu; Andrea Castoldi; Giuseppe Parisi; Fransisco Martinez R.; Andrea Bovio; Rosanna Pastorelli; Vittorio Curri"
                />
                <SessionItem
                    title="How to optimize layer-0 and layer-1 resources to ensure SLA"
                    time="12:45 - 13:10"
                    author="Annalisa Morea, NOKIA FRANCE"
                />

            
            <ConferenceEvent time="13:10 - 14:10" title="LUNCH" color="orange"/>


            <OtherDetails 
                title="From Quantum Cryptography to the Quantum Internet"
                time="14:10 - 15:10"
                type="TUTORIAL"
                author="David Bacco, Univ. of Florence, co-founder Q.T.I srl"
                abstract="/program/tutorials#bacco"
                chair="Nicola Calabretta"
            />

            <SessionDetails 
                title="QUANTUM TECHNOLOGIES AND APPLICATIONS"
                number="6" 
                time="15:10 - 16:55"
                chair="Nicola Calabretta"
            />
                <SessionItem
                    title="Quantum Approximate Optimization Algorithm for Routing Optimization in 6G Optical Networks"
                    time="15:10 - 15:30"
                    author="Oumayma Bouchmal; Bruno Cimoli; Ripalta Stabile; Juan José Vegas Olmos; Idelfonso Tafur Monroy"
                />
                <SessionItem
                    title="Optical Routing with Binary Optimisation and Quantum Annealing"
                    time="15:30 - 15:50"
                    author="Ethan Gareth Davies; Darren Banfield; Vlad Cărare; Ben Weaver; Catherine White; Nigel Walker"
                />
                <SessionItem
                    title="WDM-Enabled Classical/Quantum Coexistence over Fiber and Full Daylight FSO for 6G Networks"
                    time="15:50 - 16:10"
                    author="Aristeidis Stathis; Argiris Ntanos; Panagiotis Toumasis; Nikolaos Lyras; Giannis Giannoulis; Hercules Avramopoulos"
                />
                <SessionItem
                    title="On the Integration and Control of Quantum Key Distribution over Free-Space Optics and 5G Networks"
                    time="16:10 - 16:30"
                    author="Romerson D Oliveira; Peide Zhang; Zoe C. M. Davidson; Emilio Hugues-Salas; Evangelos A. Kosmatos; Alexandros Stavdas; Andrew Lord; John Rarity; Reza Nejabati; Dimitra Simeonidou"
                />
                <SessionItem
                    title="Towards Large-Scale Entanglement Distribution Quantum Communication Networks"
                    time="16:30 - 16:55"
                    author="Rui Wang (Univ Bristol, UK)"
                />


            <ConferenceEvent time="16:55 - 17:25" title="COFFEE BREAK" color="#57B732"/>

            <SessionDetails
                title="QUANTUM KEY DISTRIBUTION AND NETWORK CONTROL"
                number="7"
                time="17:25 - 19:15"
                chair="Zuqing Zhu (University of Science and Technology of China)"
            />
                <SessionItem
                    title="Continuous-Variable Quantum Key Distribution for Enabling Sustainable Secure 6G Networks"
                    time="17:25 - 17:50"
                    author="Michela Svaluto Moreolo; Masab Iqbal; Arturo Villegas; Laia Nadal; Ramon Casellas; Raul Muñoz"
                />
                <SessionItem
                    title="SDN-Enabled Continuous-Variable QKD in Coexistence with 8×200 Gb/s 16-QAM Classical Channels"
                    time="17:50 - 18:05"
                    author="Masab Iqbal; Arturo Villegas; Laia Nadal; Michela Svaluto Moreolo; Raul Muñoz; Pol Adillon; Samael Sarmiento; Jeison Tabares; Sebastian Etcheverry"
                />
                <SessionItem
                    title="Hacking Quantum Key Distribution over Space Division Multiplexing System"
                    time="18:05 - 18:30"
                    author="Chao Lei; Rui Lin; Paolo Monti"
                />
                <SessionItem
                    title="Impact of Lightpath Selection on End-To-End Service Orchestration in Disaggregated Optical Networks"
                    time="18:30 - 18:50"
                    author="Gianluca Davoli; Raffaele Di Tommaso; Alessio Giorgetti; Carla Raffaelli"
                />
                <SessionItem
                    title="Toward High-Capacity and Energy-Efficient Optical Networks"
                    time="18:50 - 19:15"
                    author="Nicola Sambo; Filippo Cugini; Piero Castoldi"
                />

            <ConferenceEvent time="20.15 - on" title="GALA DINNER" color="#27CAD4"/>

            {/*
                Thursday
            */}
            <ConferenceDay day="Thursday, May 9"/>

            <OtherDetails 
                title="Transport Slicing from the perspective of the IETF"
                time="9:00 - 10:00"
                type="TUTORIAL"
                author="Luis Miguel Contreras (Telefonica CTIO, Spain)"
                abstract="/program/tutorials#contreras"
                chair={"Alejandra Beghelli"}
            />

            <SessionDetails
                title="WDM SYSTEMS AND TRANSMISSION II"
                time="10:00 - 10:45"
                number="8"
                chair={"Alejandra Beghelli"}
            />
                <SessionItem
                    title="Near Real-Time Autonomous Multi-Flow Routing with Dynamic Optical Bypass Management"
                    time="10:00 - 10:15"
                    author="Sima Barzegar; Hailey Shakespear-Miles; Faris Alhamed; Francesco Paolucci; Pol Gonzalez; Marc Ruiz; Luis Velasco"
                />
                <SessionItem
                    title="Maximizing the Transport Capacity of Optical Multi-Band WDM Systems Through Power Optimization"
                    time="10:15 - 10:30"
                    author="Kostas Nikolaou; Dimitris Uzunidis; Farhad Arpanaei; José Manuel Rivas Moscoso; David Larrabeiti; Ioannis Tomkos"
                />
                <SessionItem
                    title="Filtering Power Penalty Evaluation of Coherent Systems Affected by ASE and Transceiver Noise"
                    time="10:30 - 10:45"
                    author="Pablo Torres-Ferrera; Giuseppe Rizzelli; Antonio Napoli; Roberto Gaudino"
                />


            <ConferenceEvent time="10:45 - 11:15" title="COFFEE BREAK" color="#57B732"/>

            
            <SessionDetails 
                title="MACHINE LEARNING IN OPTICAL NETWORKS AND MULTIBAND"
                number="9" 
                time="11:15 - 13:15"
                chair="Jorge M Finochietto (National University of Cordoba)"
            />

                <SessionItem
                    title="ML techniques applied on optical network monitoring"
                    time="11:15 - 11:40"
                    author="Marija Furdek (Chalmers University of Technology, Sweden)"
                />
                <SessionItem
                    title="Deep Reinforcement Learning for Resource Allocation in Multi-Band Optical Networks"
                    time="11:40 - 12:00"
                    author="Abdennour Ben Terki; Joao Pedro; António Eira; Antonio Napoli; Nicola Sambo"
                />
                <SessionItem
                    title="Reducing Complexity and Enhancing Predictive Power of ML-Based Lightpath QoT Estimation via SHAP-Assisted Feature Selection"
                    time="12:00 - 12:20"
                    author="Hussein Fawaz; Farhad Arpanaei; Davide Andreoletti; Ihab Sbeity; José Alberto Hernández; David Larrabeiti; Omran Ayoub"
                />
                <SessionItem
                    title="Exploiting GNN and DRL for Online Service Provisioning over Elastic Optical Networks"
                    time="12:20 - 12:35"
                    author="Carlos Hernández-Chulde; Ramon Casellas; Ricardo Martinez; Ricard Vilalta; Raul Muñoz"
                />
                <SessionItem
                    title="Neural Network-Based Control of TDFA"
                    time="12:35 - 12:50"
                    author="Margita Radovic; Andrea Sgambelluri; Nicola Sambo; Piero Castoldi"
                />
                <SessionItem
                    title="Optical switching for multiband networks"
                    time="12:50 - 13:15"
                    author="Nicola Calabretta (Technical Univ. of Eindhoven, NL)"
                />
                

            <ConferenceEvent time="13:15 - 13:45" title="LUNCH" color="orange"/>


            <SessionDetails 
                title="RESILENCE IN OPTICAL NETWORKS"
                number="10" 
                time="13:45 - 15:45"
                chair="Yvan Pointurier (Huawei, France)"
            />
                <SessionItem
                    title="Content Evacuation in Inter-DC Optical Networks Under Post-Disaster Cascading Failures"
                    time="13:45 - 14:05"
                    author="Zhuotong Li; Memedhe Ibrahimi; Yongli Zhao; Biswanath Mukherjee; Jie Zhang; Massimo Tornatore"
                />
                <SessionItem
                    title="PCA-Assisted Fuzzy Clustering Approach for Soft-Failure Detection in Optical Networks"
                    time="14:05 - 14:25"
                    author="Andrei Nogueira Ribeiro; Rafael Sales; Fabrício Lobato; Moisés Felipe Silva; Joao Weyl Costa; Andrea Sgambelluri; Luca Valcarenghi; Lena Wosinska"
                />
                <SessionItem
                    title="DRAMA-DC: Disaster Recovery Algorithm with Mitigation Awareness in Data Center EONs"
                    time="14:25 - 14:45"
                    author="Rujia Zou; Shih-Chun Lin; Motoharu Matsuura; Hiroshi Hasegawa; Suresh Subramaniam" 
                />
                <SessionItem
                    title="A Distributed-Ledger-Based Multi-Entity Cooperation Platform for Network-Cloud Recovery"
                    time="14:45 - 15:05"
                    author="Sugang Xu; Subhadeep Sahoo; Noboru Yoshikane; Sifat Ferdousi; Masaki Shiraiwa; Yusuke Hirota; Takehiro Tsuritani; Massimo Tornatore; Yoshinari Awaji; Biswanath Mukherjee"
                />
                <SessionItem
                    title="Optimizing Deep Learning-Based Failure Management in Optical Networks by Monitoring Relative Neural Activity"
                    time="15:05 - 15:20"
                    author="Lareb Zar Khan; Joao Pedro; Omran Ayoub; Nelson Costa; Andrea Sgambelluri; Lorenzo De Marinis; Antonio Napoli; Nicola Sambo"
                />
                <SessionItem
                    title="End-To-End Link Availability Assessments in an Optical Spectrum as a Service Use-Case"
                    time="15:20 - 15:45"
                    author="Kaida Kaeval"
                />

            <SessionDetails 
                title="BEST PAPER AWARD, ONDM 2025 AND CLOSURE"
                time="15:45 - 16:15"
                chair="David Larrabeiti (Univ. Carlos III de Madrid) and Luca Valcarenghi (Scuola Superiore Sant'Anna, Pisa, Italy)"
            />
        </Box>
    )
}

const SessionDetails = ({title, number, time, chair, chairTitleCustom, otherChairs=[]}) => 
<div style={{background: "#E9E9E9", padding: 10, marginTop: 20, border: "0px solid black", boxShadow: "0px 3px 5px black"}}>
    <h4 style={{margin: 0}}>{number ? "Session S" + number : ""} [{time}] {title}</h4>
    <b>{chairTitleCustom ? chairTitleCustom : "Chair"}:</b> {chair}
    {otherChairs.map(chair => 
        <div>
            <b>{chair.chairTitleCustom ? chair.chairTitleCustom : "Chair"}:</b> {chair.chair}
        </div>
        )}
</div>

const OtherDetails = ({title, time, author, abstract, type, chair}) => 
<div style={{background: "#E9E9E9", padding: 10, marginTop: 20, border: "0px solid black", boxShadow: "0px 3px 5px black"}}>
    <h4 style={{margin: 0}}>[{time}] {type} </h4>
    <div>{author}</div>
    <div><b>Title: </b>{title}</div>
    {abstract ? <div> <Link to={abstract}><b>Abstract</b></Link></div> : ""}
    <div><b>Chair:</b> {chair}</div>
</div>

const SessionItem = ({title, time, author}) =>
<div style={{marginTop: 20}}>
    <h4 style={{margin: 0}}>
    [{time}] {title}
    </h4>
    {author}
</div>

const ConferenceDay = ({day}) => 
<h2>
    {day}
</h2>

const ConferenceEvent = ({time, title, color, chair}) => 
<div style={{backgroundColor: color, padding: 5, marginTop: 10,  boxShadow: "0px 3px 3px black"}}>
    <b>[{time}] {title}</b> 
    {chair ? <div>
        <b>Chair:</b> {chair}
        </div> : ""}
</div>


const WorkshopDetails = ({workshopNumber, titleA, titleB, organizersA, organizersB, speakersA, speakersB, time, abstractA, abstractB, roomA, roomB}) =>
<div style={{marginTop: 20}}>
<div style={{marginBottom: 10}}>
<b >[{time}] WORKSHOPS</b>
</div>
<Stack direction={"row"} spacing={2}>
<div style={{background: "#E9E9E9", width: "100%", padding: 10, marginBottom: 20, border: "0px solid black", boxShadow: "0px 3px 5px black"}}>
    <b>WS{workshopNumber}A - {titleA}</b> 
    <div>
        <b>Organizers:</b> {organizersA}	
    </div>
    <div>
        <b>Speakers: </b> 
        {speakersA}
    </div>
    <b>Room:</b> {roomA}
    <div><b><Link to={abstractA}>Abstract</Link></b></div>
</div>
<div style={{background: "#E9E9E9", width: "100%",padding: 10, marginBottom: 20, border: "0px solid black", boxShadow: "0px 3px 5px black"}}>
    <b>WS{workshopNumber}B - {titleB}</b> 
    <div>
        <b>Organizers:</b> {organizersB}	
    </div>
    <div>
        <b>Speakers: </b> 
        {speakersB}
    </div>
    <b>Room:</b> {roomB}
    <div><b><Link to={abstractB}>Abstract</Link></b></div>
</div>
</Stack>
</div>


