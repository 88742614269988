import { Grid } from "@mui/material";
import { Box } from "@mui/system";


export default function InvitedSpeakers(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "200px"}}>
            <h1><strong>Invited Speakers</strong></h1>
            <InvitedSpeaker
                author="Arash Vakili"
                title="Embracing the AI Era: The Evolution and Planning of a Petabit scale network"
                affiliation="Meta, USA"
                abstract="We are currently experiencing an exceptional surge in network traffic volume, largely driven by AI. Network Planners are tasked with the formidable challenge of accommodating this expansion, while also considering a variety of constraints such as Space & Power, physical fibre limitations, supply chain issues, and turn-up time. Moreover, managing these factors in a mature peta-bit scale network brings its own unique set of challenges. These include dealing with contention, operational delays, risks, and changes in traffic patterns, among many other unpredictable factors. This presentation will guide us through the complex challenges associated with network planning in the face of rapid traffic growth driven by AI. It necessitates a range of strategies in Traffic Forecasting and Management, Automated Closed Loop Network Planning, Scalable Network Infrastructure, Pro-active Risk Management, Supply Chain Management, and Continuous Improvement to stay ahead of the demand growth curve. It's crucial to remember that every network is unique, and as such, these strategies need to be tailored to fit each specific situation."
                photo="/portraits/Arash_Vakili.jpg"
            />
            <InvitedSpeaker
                author="Jorge Finochietto"
                title="Bottom-Up Design of Digital Twins for Optical Transport Networks: Challenges and Lessons Learned"
                affiliation="Universidad Nacional de Córdoba, Argentina"
                abstract="We discuss the design and development of a digital twin for optical transport networks, focusing on a bottom-up approach aligned with network operators' specific needs. Our methodology was carved by prioritizing operator requirements, aiming to enhance the management and analysis of optical networks. Having a digital twin enabled gathering telemetry data from the network to assess its health and support predictive maintenance.  We navigate through various challenges, from model accuracy to user interface design, and share the lessons learned from these experiences, which helped to implement and validate it in real networks."
                photo="/portraits/Jorge_Finochietto.jpg"
            />
            <InvitedSpeaker
                author="Michela Svaluto Moreolo"
                title="Continuous-Variable Quantum Key Distribution for Enabling Sustainable Secure 6G Networks"
                affiliation="Research Director at Centre Tecnològic de Telecomunicacions de Catalunya (CTTC/CERCA), Spain"
                abstract="Several technological challenges are currently posed by future 6G networks to support ultra-high capacity and dynamic connectivity, while ensuring environmental and societal sustainability, scalability, and reliability.  Among them, security is a crucial aspect, particularly considering open and disaggregated networks. Continuous variable quantum key distribution (CV-QKD) is proposed as an appealing solution to ensure long-term security, while facilitating the coexistence with conventional optical communications for a sustainable integration of QKD technology in future 6G networks. In this talk, challenges and opportunities of adopting CV-QKD in future optical networks are presented and discussed, focusing on the network resources and infrastructure sharing, exploring advanced features, flexibility and programmability, further enabled by software defined networking."
                photo="/portraits/Michela_Svaluto.jpg"
            />
            <InvitedSpeaker
                author="Andrea Marotta"
                title="Dynamic Spatial Aggregation for Energy-Efficient Passive Optical Networks"
                affiliation="Assistant Professor, University of L'Aquila, Italy"
                abstract="This paper explores enhanced energy efficiency in Passive Optical Networks (PONs) using dynamic spatial aggregation. It introduces an adaptive strategy that aligns network operations with variable traffic patterns, significantly improving energy usage. The method relies on the strategic activation and deactivation of specific spatial lanes, e.g., cores in multi-core fibers or fibers in multi-fiber systems, leading to a substantial decrease in energy consumption, achieving up to a 38% energy savings."
                photo="/portraits/Andrea_Marotta.png"
            />
            <InvitedSpeaker
                author="Annalisa Morea"
                title="Planning of WDM Multi-Regional Network for trading-off operational use of resources: cost versus resource recovery capability"
                affiliation="Nokia"
                abstract="We consider a pragmatic multi-layer capacity planning approach for a multilayer (Layer1 and Layer0) OTN networks resorting to existing planning practices. We first describe the Layer1 service recovery requirements and then the cost savings that are possible by coordinating the Layer0 and Layer1 network status. We compute the savings achieved on two real-world core network models by assessing the design and cost impacts of different multilayer restoration schemes. In that framework, we identify how the most promising application cases depend on the network and traffic characteristics."
                photo="/portraits/Annalisa_morea.jpg"
            />
            <InvitedSpeaker
                author="Kaida Kaeval"
                title="End-to-end path availability assessments in open optical networks: addressing the multi-operator challenge"
                affiliation="Tallinn University of Technology, Estonia"
                abstract="Open and disaggregated approach to optical transport networks has enabled the emergence of new service models such as Optical Spectrum as a Service (OSaaS). While captivating for Open Line System (OLS) operators, operation in multi-operator scenarios raises several end-to-end performance and availability concerns, as the data describing each OLS is often considered business critical. This paper examines end-to-end path availability estimations using readily accessible telemetry data from commercial coherent transceivers and presents the method`s usability in live multi-operator environments."
                photo="/portraits/kkaeval.jpg"
            />
            <InvitedSpeaker
                author="Nicola Sambo"
                title="Toward high-capacity and energy-efficient optical networks"
                affiliation="Scuola Superiore Sant'Anna, Italy"
                abstract="The growth of traffic demand induces network operators to extend network capacity. Multi band is an attractive solution to support traffic increase. In parallel, climate change imposes to explore solutions to reduce and make scalable the network energy consumption, especially because energy consumption increases with traffic. This paper discusses network migration to multi band while accounting for energy efficiency."
                photo="/portraits/Nicola_Sambo.jpg"
            />
            <InvitedSpeaker
                author="Rui Lin"
                title="Hacking Quantum Key Distribution Over Space Division Multiplexing System"
                affiliation="Chalmers University of Technology, Sweden"
                abstract="Quantum key distribution offers unparalleled security based on the principles of quantum mechanics. On the other hand, space division multiplexing (SDM) is recognized as the route toward a capacity upgrade for current core optical communication networks and will progressively permeate into the shorter-reach networks. Incorporating QKD into the classical network, with SDM being one of its components will enhance the data communication security to a large extent. however, it is imperative to acknowledge and address potential weaknesses of QKD. Understanding the security implications of implementing QKD over SDM is crucial for the practical deployment of quantum-secure communication technologies, especially in scenarios where high data capacity and multiplexing techniques are essential."
                photo="/portraits/Rui_Lin.jpeg"
            />
            <InvitedSpeaker
                author="Rui Wang"
                title="Building An Entanglement Distribution Network in Bristol"
                affiliation="University of Bristol, UK"
                abstract="Entanglement serves as a crucial component in quantum networks. In this talk, an extensive journey in Bristol to build entanglement distribution networks will be discussed. The discussion begins by assessing the current state of the art in this field and proceeds to explore an approach employed for generating and disseminating entangled photons on a network-wide scale. Additionally, the talk provides a brief overview of the implementation of BBM92 protocols to exploit entangled photon pairs for quantum key generation. The outcomes of entanglement-based network experiments using such a protocol will be shared. Various aspects within the scope of this presentation include strategies for network optimization, the incorporation of AI-controlled mechanisms, and the coexistence of quantum and classical links over the same fiber, and the results of a series of network experiments in this context will be presented."
                photo="/portraits/Rui_Wang.jpg"
            /> 
            <InvitedSpeaker
                author="Marija Furdek"
                title="Optical network security in increasingly insecure times"
                affiliation="Chalmers University of Technology, Sweden"
                abstract="Optical fiber links underpin the global communication network, but their security has traditionally been neglected. Times of growing international hostilities reveal the pitfalls of such practice: optical network infrastructure becomes an enticing target of attacks, and its security is more concerning than ever. What are the main security vulnerabilities in optical networks, how do attackers exploit them, what can currently be done about it, and what else is needed to protect the network? We examine the current capabilities in preventing, detecting and reacting to physical-layer attacks, propelled by the recent advances in optical network security management, and give a perspective on the impending challenges."
                photo="/portraits/Furdek.jpg"
            /> 
            <InvitedSpeaker
                author="Nicola Calabretta"
                title="Photonic integrated WDM switching nodes for multiband optical networks"
                affiliation="Professor at Eindhoven University of Technology, Eindhoven, The Netherlands"
                abstract="To meet the increasing demand of high capacity in optical networks, exploiting multi-band optical fiber transmission (MBT) operation from O to L bands is an attractive solution to significantly improve the current optical network capacity. The main challenge for MBT is the lack of mature wideband network components. Especially, MBT photonic wavelength selective switches (MBT_WSS) and cross-connect switch matrices (MBT_OXC) for reconfigurable wideband network nodes that dynamically route the wideband optical signals and network traffic are key network elements. In this work we report the assessment of a monolithic and an hybrid photonic integrated MBT_WSS for metro access and beyond 5G distribution networks, as well as a photonic integrated MBT_OXC."
                photo="/portraits/calabretta.jpg"
            /> 
            <h1><strong>Sponsor Invited Talks</strong></h1>
            <InvitedSpeaker
                author="Liang Zhang"
                title="The driving force and technology challenges for the optical network 2025-2030"
                affiliation="Expert of Optical Standardization and Industry Development, Huawei, France. Email: zhangliang12@huawei.com"
                abstract={
                <>
                    <p>
                        ETSI ISG F5G mentioned ‘fiber to everywhere’ in 2020, FTTH council proposed ‘Everyone and everything on Fiber’ in 2023.  Indeed, optical fiber has become the most critical ICT infrastructure and optical communication has become the key enabler for the intelligent world.

                        By 2025-2030, multiple new services and scenarios will emerge:

                               1. AI and big data drive interconnection of mega data centers, requiring higher bandwidth connections and larger cross-connection dimensions.

                               2. Home scenarios: 8K videos, and 3D videos drive the access network to provide higher bandwidth, lower latency, and low jitter.

                               3. Smart campuses require more scenario-based AR applications and real-time interaction.

                       Facing so many application scenarios, the optical network requires E2E technology innovation.

                               1. The backbone network needs to evolve from 400G to 800G/1.6T, the single-fiber capacity needs to reach 100 Tbit/s, and the dimension of OXC may surpass 100 degrees, which require innovation in optical components, algorithms, OAs, and OXC.

                               2. The access network requires higher bandwidth and guaranteed-experience E2E connections. Technically, the access network needs to evolve from 50GPON to 100G/200GPON and support FTTR 2.0.

                               3. For intelligent O&M and management and control, L4 or even L5 autonomous driving is required. How AI-LLM assists optical networks is also a topic worth discussing.

                       In the intelligent era, optical networks will play an increasingly important role in 2025-2030. Industry, academia, and research institutes need to work together to promote technological progress.
                    </p>
                </>
                }

                photo="/portraits/Zhang_Liang.jpg"
            />
            <InvitedSpeaker
                author="Achim Autenrieth"
                title="Automation of open and disaggregated optical transport networks – Challenges and research highlights for the emerging AI-driven network"
                affiliation="Director Advanced Technology, Adtran, Germany. Email: achim.autenrieth@adtran.com"
                abstract={
                <>
                    <div>
                        Co-authors: Jasper Müller, Vignesh Karunakaran
                    </div>
                    <p>
                        In this talk we explore challenges and research highlights for the automation of future AI-driven disaggregated optical transport networks. Leveraging SDN control and streaming telemetry for real-time network configuration management and monitoring facilitates network data analytics and machine-learning for closed loop automation.

                        Throughout the talk, we showcase research highlights and innovative solutions from the funded research projects SEASON and AI-NET-PROTECT, addressing the diverse challenges of automating disaggregated optical transport networks. From SDN-based control mechanisms to machine learning-driven insights, our exploration underscores the transformative potential of automation in realizing agile, scalable, and resilient optical transport infrastructures. We conclude with an outlook on current research topics and open challenges.
                    </p>
                </>
                }
                
                photo="/portraits/A.Autenrieth.jpeg"
            /> 
            <InvitedSpeaker
                author="Camille Delezoide"
                title="Sensing in optical networks: from raw monitoring data to advanced failure analysis and beyond"
                affiliation="Senior research engineer in Nokia Bell Labs"
                abstract={
                <>
                    <div>
                    The recent commercial availability of software tools such as Nokia’s Wavesuite Health & Analytics now allows for the large-scale collection of multiple data streams from a myriad of sensors nested in most optical network devices. This can effectively transform all deployed networks into massive experimental testbeds from which there is a great deal to observe and learn, with countless applications from preventive maintenance to network optimization. In that context, we will review the most promising technologies leveraging readily available data to detect, diagnose and localize network anomalies to prevent major disruptions and ensure the security of the network infrastructure.
                    </div>
                </>
                }
                
                photo="/portraits/Delezoide.jpg"
            /> 
        </Box>
    )
}


function InvitedSpeaker({author, affiliation, title, abstract, photo}){
    return(
        <div style={{marginTop: 25}}>

            <Grid container>

                <Grid item xs={3}>
                <img src={photo} alt={`${author}'s portait`} style={{width: "90%", margin: "auto", borderRadius: 5, marginTop: 30}}/>
                </Grid>
                <Grid item xs={9}>
            <h2>{title}</h2>
                <div style={{marginTop: 10}}>
                    <b>{author}</b>, {affiliation}
                </div>
                <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' }}}>
                <div style={{textAlign: "justify", marginTop: 18}}>
                    {abstract}
                </div>
                </Grid>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item md={9} xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                <div style={{textAlign: "justify", marginTop: 12}}>
                    {abstract}
                </div>
                </Grid>
            </Grid>
        </div>

    )
}
