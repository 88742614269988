import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(level, fee, desc) {
  return { level, fee, desc };
}

const rows = [
  createData('Platinum', "5000€", <div>
    <ul>
        <li>Two complimentary conference registrations including two invitations to the social event</li>
        <li>Invited speech in the opening session</li>
        <li>Logo on the program of the meeting</li>
        <li>Logo on the website of the meeting, with link to the website of the organization</li>
        <li>Company information, brochures or other material included in the meeting bags</li>
    </ul>
  </div>),
    createData('Gold', "2000€", <div>
    <ul>
        <li>One complimentary conference registrations including one invitation to the social event</li>
        <li>Invited talk</li>
        <li>Logo on the program of the meeting</li>
        <li>Logo on the website of the meeting, with link to the website of the organization</li>
        <li>Company information, brochures or other material included in the meeting bags</li>
    </ul>
  </div>),
    createData('Silver', "1000€", <div>
      <ul>
          <li>One complimentary conference registrations including one invitation to the social event</li>
          <li>Logo on the program of the meeting</li>
          <li>Logo on the website of the meeting, with link to the website of the organization</li>
          <li>Company information, brochures or other material included in the meeting bags</li>
      </ul>
    </div>),
    createData('Bronze', "500€", <div>
    <ul>
        <li>Logo on the program of the meeting</li>
        <li>Logo on the website of the meeting, with link to the website of the organization</li>
    </ul>
    </div>),
    createData('Best Paper Award Sponsor', "1500€", <div>
    <ul>
        <li>Speech at the Award Ceremony</li>
        <li>Logo on the program of the meeting</li>
        <li>Logo on the website of the meeting, with link to the website of the organization</li>
        <li>Company information, brochures or other material included in the meeting bags</li>
    </ul>
    </div>),
];

export default function SponsorTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Level</TableCell>
            <TableCell align="center">Fee</TableCell>
            <TableCell>Included benefits</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.level}</TableCell>
              <TableCell align="center">{row.fee}</TableCell>
              <TableCell>{row.desc}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
