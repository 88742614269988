import { Box } from "@mui/system";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function Workshops(){

    const location  = useLocation();
    const [selectedWS, setSelectedWS] = useState();
    
    const refs = [
        useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)
    ]

    const ids = ["wsMachineLearning", "wsDataExploitation", "wsEndToEnd", "wsSecurity", "wsPhysicalLayer"];

    const scrollToElement = (refIndex) => {
        const {current} = refs[refIndex];   
        if (current !== null){
            current.scrollIntoView({behavior: "smooth"})
        }
    }


    useEffect(() => {
        if(location.hash !== ""){
            let hash = location.hash.split("#")[1]
            if(ids.includes(hash)){
                let index = ids.indexOf(hash)
                scrollToElement(index)
                setSelectedWS(ids[index])
                setTimeout(() => {
                    scrollToElement(index)
                }, 200);
            }

        }
        // eslint-disable-next-line
    }, [location, selectedWS]);

  return (
    <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "200px"}}>
        <h1><strong>Workshops</strong></h1>

        <Workshop
            title="Machine Learning in Optical Networks"
            ws_id={ids[0]}
            ref_instance={refs[0]}
            curentInstance={selectedWS}
            data={<>
                <Divider sx={{mb:3}}/>
                <WorkshopPart 
                    title={"Part 1: Applying Machine Learning to Optical Networks in Real-World Production Environments"} 
                    organizers={"Marija Furdek Prekratic (Chalmers), Stephan Pachnicke (Kiel University), Achim Autenrieth (ADTRAN)"}
                    abstract={"Future-proofing optical networks is an extremely challenging task. Powerful AI tools are anticipated to contribute to achieving the ambitious goals of increasing bandwidth, improving resilience and reliability, and reducing latency and cost. The development of such tools takes a large amount of time and resources. High-quality data sets are needed to build robust and generalizable applications and frameworks for telemetry data collection and data sharing are required.  Many machine learning (ML) applications for optical networks have been proposed and researched in the past years but have yet to be deployed in production environments."}
                    speakers={
                        <>
                            <WorkshopSpeakers title={"Sensing"} speakers={[
                                "Sai Patri (ADTRAN, Germany), Ideas to Impact: “Leveraging ML Research in Fiber Infrastructure Monitoring Products”"
                            ]}/>
                            <WorkshopSpeakers title={"QoT estimation & Failure Prediction"} speakers={[
                                "Lars Kruse (Kiel University, Germany), “Generative AI for Soft-Failure Identification in Optical Networks”",
                                "Jasper Müller (ADTRAN, Germany), “Telemetry-Driven Insights and Quality of Transmission Estimation in Production Networks”",
                                "Christina Rottondi (Politecnico di Torino, Italy), “Leveraging explainable artificial intelligence for feature engineering in machine learning algorithms for optical networking”"
                            
                            ]}/>
                            <WorkshopSpeakers title={"Network Optimization"} speakers={[
                                "Alejandra Beghelli (UCL), “Deep reinforcement learning for resource allocation”",
                                "Behnam Shariati (Fraunhofer HHI), “Data Pipelines with Usage Restrictions Policies for Network Telemetry Sharing”"
                            ]}/>
                        </>
                    }
                    />
                <Divider sx={{my:3}}/>
                <WorkshopPart 
                    title={"Part 2: Optical networks roadmap: chart out applications and needs, Machine Learning in optical networks"} 
                    organizers={"Francesco Musumeci (Politecnico di Milano), Xiaoliang Chen (Sun Yat-Sen University), Daniel Kilper (Trinity College Dublin), Lena Wosinska (Chalmers University of Technology), Zuqing Zhu (University of Science and Technology of China)"}
                    abstract={"The rising complexity of optical networks and the increased amount of information retrievable from the field are leading network operators to investigate on efficient strategies to exploit the available data and optimize the design and management of future optical networks. Efficient data analysis and data processing methodologies inherited by Machine Learning and Artificial Intelligence (ML/AI) are nowadays widely considered as key enablers in this context. Through invited talks and panel discussion, the aim of the workshop is to bring together experts from industry and academia working on the applications of ML/AI in the optical networking domain to share knowledge and vision about the adoption of ML/AI techniques to fully automate optical networks. Several topics will be discussed during the workshop, highlighting the most recent methodological and technological advances, with a look at future network requirements enabling wide use of ML, network automation and novel applications."}
                    speakers={
                        <>
                            <WorkshopSpeakers title={""} speakers={[
                                "Yvan Pointurier (Huawei, France), “ML for optical networks – a reality check”",
                                "Luis Velasco (Universitat Politècnica de Catalunya, Spain), “Distributed Secure control of optical connectivity: Requirements and challenges”",
                                "Shuangyi Yan (University of Bristol, UK), “AI/ML in Optical Networks: Harnessing AI Engine, Digital Twin, and Telemetry”",
                                "Nicola Sambo (Scuola Superiore Sant’Anna, Italy), “Potentials of smart network interface cards for ML processing in optical network applications”",
                                "Antonio Napoli (Infinera, Germany), “Machine Learning for Improved Network Performance”",
                                "Dan Kilper (Trinity College Dublin, Ireland), “IEEE INGR AI/ML in Optical Networks: 2024 Draft Roadmap”"
                            ]}/>
                        </>
                    }
                        
                />
                <Divider sx={{my:3}}/>
                <p>
                <b>Supporting projects:</b>
                </p>
                <ul>
                    <li>IEEE Future Networks Optics WG</li>
                    <li>ECO-eNET project</li>
                    <li>6G-Life project</li>
                    <li>AI-NET PROTECT</li> 
                </ul>
                </>}
        />
        <Workshop
            title="Data exploitation over the 6G edge/cloud continuum"
            ws_id={ids[1]}
            ref_instance={refs[1]}
            curentInstance={selectedWS}
            data={<>
                <Divider sx={{my:3}}/>             
                <WorkshopPart 
                    organizers={"Carlos Jesús Bernados Cano (University Carlos III of Madrid, Spain), Antonio de la Oliva (University Carlos III of Madrid, Spain)"}
                    abstract={"Network disaggregation coupled with virtualization has made it possible for innovative services, such as those related to the connected industry, to be run over the network edge. This evolution has made networks more agile in responding to such stringent service needs by exploiting the vast amount of data generated by constituent functions. These data enable the automation of network management and orchestration as well as innovative services by leveraging Artificial Intelligence. The timely delivery of data to all the pertinent nodes involved in such critical operations as closed-loop zero touch automation necessitates the development of enhanced communication mechanisms throughout the network. Optical networks (serving as the backbone for data transmission over the edge/fog/cloud compute continuum) is envisaged to play a critical role in facilitating these advanced use cases. This workshop will explore advanced data dissemination/exploitation solutions for the connected industry leveraging optical networks and discuss their broader implications to other emergent verticals. "}
                    speakers={
                        <>
                            <WorkshopSpeakers title={""} speakers={[
                                "Aitor Zabala Orive (Telcaria, Spain), “Innovative network services exploiting the compute continuum”",
                                "Ernesto Correa Velandia (Capgemini, Spain), “Exploitation of distributed data in industrial environments”",
                                "Marc Molla Rosello (Ericsson, Spain), “Enhanced Digital twinning for holistic network service management”",
                                "Carlos Jesús Bernados Cano (University Carlos III of Madrid, Spain), “Deterministic networking for connected industry applications”",
                                "Ignacio Labrador Pavon (Eviden, Spain), “Decentralised network services orchestration: a proposal to integrate the extreme-edge domain in the future 6G networks”",
                                "Antonio de la Oliva (University Carlos III of Madrid, Spain), “Digital Twins for the hyper-distributed 6G edge network”" 
                            ]}/>
                        </>
                    }
                />
                <Divider sx={{my:3}}/>
                <p>
                <b>Supporting projects:</b>
                </p>
                <ul>
                    <li>6G-DATADRIVEN</li>
                    <li>6G-EDGEDT</li>
                </ul>
                </>}
        />
        <Workshop
            title="End-to-end network for 2030 and beyond: from F5G-Advanded to F6G"
            ws_id={ids[2]}
            ref_instance={refs[2]}
            curentInstance={selectedWS}
            data={<>
                <Divider sx={{my:3}}/>
                <WorkshopPart
                    organizers={"Yvan Pointurier (Huawei, France), Raul Muñoz (CTTC, Spain)"}
                    abstract={"Optical networking research now focuses on the 2030+ network (both access and transport) to support the next generation of 5G communication (5G advanced) and then 6G communication. The next-generation optical networks will enable new applications such as immersive experience services, enterprise digitalization and cloudification, industrial applications of optical networks, 4K and 8K HD video, meta universe and new network evolutions such as digital network operations, ubiquitous fiber infrastructure, intelligent infrastructure, green technologies, and carbon emission reduction etc. Its key features are envisioned to be: higher capacity, more autonomous operation, more fiber penetration (bring fiber closer to the user), lower energy consumption, better resilience and experience reliability, and Real-time Resilient Link (RRL) and enhanced sensing and visualization ability. We propose a workshop to be held on the 2030+ optical network, in line with the ETSI standardization body proposal for F5G-Advanced/F6G including Use cases; High bandwidth(10-100Gbps) per use bandwidth; ~10μs level low latency; Converged network and sensing, which digitizes and visualizes the network infrastructure and sense the environment surround the network infrastructure to provide add values; Network Digital Twins which support L4/L5 Network automation; All-optical network architecture and technologies with high energy efficiency and high bandwidth; End-to-End Quality of Experience assurance; Network energy efficiency measurement and improvement."}
                    speakers={
                        <>
                            <WorkshopSpeakers title={""} speakers={[
                                "Juan Pedro Fernandez-Palacios Gimenez Telefonica, “Energy measurements, control and use cases for power optimization in IP/DWDM networks”",
                                "Ligia Maria Moreira Zorello, Meta, “Optical network strategies to scale the backbone”",
                                "Jörg-Peter Elbers, Adtran, “Optical networks - from challenges in the past to future opportunities”",
                                "Sébastien Bigo, Nokia, “Future of optical non-terrestrial communication”",
                                "Liang Zhang, Huawei, “All-optical network architecture and technologies with high energy efficiency and high bandwidth”",
                                "Ricard Vilalta, “Enhancing Network Automation with Network Digital Twins”"
                            ]}/>
                        </>
                    }
                />
                <Divider sx={{my:3}}/>
                <p>
                <b>Supporting projects:</b>
                </p>
                <ul>
                    <li>6G-OPENSEC</li>
                </ul>
                </>}
        />
        <Workshop
            title="Security in Classical Optical Communications and Quantum Technologies"
            ws_id={ids[3]}
            ref_instance={refs[3]}
            curentInstance={selectedWS}
            data={<>
                <Divider sx={{my:3}}/>
                <WorkshopPart
                    organizers={"Luis Velasco (Universidad Politécnica de Catalunya, Spain), Roberto Proietti (PoliTo, Italy)"}
                    abstract={"As security concerns are growing in B5G/6G networks, traditional and quantum-based cryptographic methods need to be developed to enable reliable and secure communication systems. Advanced classical approaches that leverage on the unique characteristics of the materials can be used for authentication. They can work together with QRNGs and QKD to offer physical authentication in large networks. Based on fundamental quantum principles, these quantum approaches present a significant contrast to classical cryptographic methods reliant on computational complexity and mathematical algorithms. In addition, improved practical trusted-node security and a key management system (KMS) need to scale and adapt to growing and dynamically changing networks and user groups. These solutions need to be integrated and controlled to provide complete secure E2E mechanisms that can protect communication networks from various attacks, including eavesdropping, jamming, and impersonation. This workshop will explore both non-quantum and quantum security solutions aiming to enhance the security of B5G/6G networks. Specifically: advanced non-quantum security solutions; quantum solutions and current deployments; the control of the secured infrastructure, including the design and implementation of secure SDN agents, and secure ML pipelines."} 
                    speakers={
                        <>
                            <WorkshopSpeakers title={""} speakers={[
                                "Ramon Casellas (CTTC, Spain)",
                                "Idelfonso Tafur (Technical University of Eindhoven, Netherlands)",
                                "Vicente Martin (Universidad Politécnica de Madrid, Spain)",
                                "Antonio Pastor (Telefonica, Spain)",
                                "Vincent Lefebvre (Tages Solidshield, France)",
                                "Luca Poti (CNIT, Italy)"
                            ]}/>
                        </>
                    }
                />
                <Divider sx={{my:3}}/>
                <p>
                <b>Supporting projects:</b>
                </p>
                <ul>
                    <li>ALLEGRO</li>
                    <li>SEASON</li>
                    <li>DESIRE6G</li>
                    <li>QUANTUM Flagship</li>
                </ul>
                </>}
        />
        <Workshop
            title="Physical Layer Security: Just snake oil or quantifiable security?"
            ws_id={ids[4]}
            ref_instance={refs[4]}
            curentInstance={selectedWS}
            data={<>
                <Divider sx={{my:3}}/>
                <WorkshopPart
                    organizers={"Helmut Grießer (ADVA, Germany), Stefan Köpsell (TU Dresden / Barkhausen Institute, Germany), Christoph Lipps (DFKI, Germany)"}
                    abstract={"In addition to well established algorithmic cryptographic approaches and often motivated by the quantum computer threat for public key cryptography, scientists did and still do propose encryption, key distribution and authentication methods that are based on the properties of physical devices, the transmission channel or even quantum effects. Without sufficient insight into fundamental cryptographic concepts and the underlying physical properties of the specific methods, the concrete benefit and requirements are not always easy to understand. That does not necessarily mean, that these methods are fraudulent or faulty, but the security benefit might be much lower than implied. During the workshop several physical layer techniques are introduced and their qualitative (basis of security, limitations) as well as quantitative security (bounds) will be discussed."}
                    speakers={
                        <>
                            <WorkshopSpeakers title={""} speakers={[
                                "Momtchil Peev, Huawei (Security of QKD)",
                                "Konrad Banaszek, University of Warsaw (Optical Key Distribution)",
                                "Dan Sadot, Ben Gurion University and CyberRidge (Optical encryption)",
                                "Kai Jansen, PHYSEC (PLS for wireless)",
                                "Werner Teich, University Ulm (Coding and Scrambling for Physical-Layer Security)"
                            ]}/>
                        </>
                    }
                />
                <Divider sx={{my:3}}/>
                <p>
                <b>Supporting projects:</b>
                </p>
                <ul>
                    <li>AI-NET PROTECT</li>
                    <li>DemoQuanDT</li>
                    <li>ALPAKA</li>
                    <li>Open6GHub</li>
                </ul>
                </>}
        />
    </Box>
  );
};

function Workshop({title, data, ws_id, ref_instance, curentInstance}){

    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        console.log(curentInstance, ws_id)
        if(ws_id === curentInstance){
            setExpanded(true)
        }
    
    }, [curentInstance, ws_id])

    return(
        <Accordion expanded={expanded} onChange={(e) => setExpanded(!expanded)}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id={ws_id}
            ref={ref_instance}
            >
            <h3 style={{margin: 0}}>{title}</h3>
            </AccordionSummary>
            <AccordionDetails>
                {data}
            </AccordionDetails>
        </Accordion>
    )
}



function WorkshopPart({title, organizers, abstract, speakers}){
    return(
        <div>
            <h4 style={{margin: 0, marginBottom: 10}}>{title}</h4>
            <p style={{marginTop: 0}}><b>Organizers:</b> {organizers}</p>
            <p style={{textAlign: "justify"}}>{abstract}</p>
            {speakers}
        </div>
    )
}

function WorkshopSpeakers({title = "", speakers}){
    return(
        <div>
        <i>{title}{title !== "" ? ":" : ""}</i> {/* This prints a ":" if there is a title, title is optional*/}
        <ul>
            {speakers.map((speaker) => 
                <li>{speaker}</li>
            )}
        </ul>
        </div>
    )
}
