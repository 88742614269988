import { Box } from "@mui/system";

import Grid from '@mui/material/Grid';

import SessionsSlider from "../components/SessionsSlider";

export default function Home(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", textAlign: "justify"}}>

            <h1><strong>Featured Speakers</strong></h1>

            <SessionsSlider/>


            <div style={{marginTop: 30}}> 
                <Grid container
                    direction="row"
                    spacing={3}
                >
                    <Grid item xs={12} sm={6}>

                        <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                            <img src="best_paper_award.jpg" alt="Red Ayassi best paper award" style={{height: 500, alignSelf: "center"}}/>
                        </div>

                        <div>
                            <h2 style={{textAlign: "center"}}>Best Paper Award</h2>
                            <h3 style={{textAlign: "center"}}>Reda Ayassi</h3>
                            <p>
                            <b>Field Trial Demonstration of Digital Twin Assisted Network Optimization on a Production Network</b>
                            </p>
                            <p>
                            <b>Reda Ayassi</b>; Reda Ayassi; Xin Yang; Yvan Pointurier; Gabriel Charlet; Quoc Thai Nguyen; Yassine El Harraz
                            </p>                       
                        </div>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <img src="best-student_paper_award.jpg" alt="Red Ayassi best paper award" style={{height: 500,  alignSelf: "center"}}/>
                    </div>
                    <div>
                        <h2 style={{textAlign: "center"}}>Best Student Paper Award (shared)</h2>
                        <h3 style={{textAlign: "center"}}>Zhuotong Li & Ethan Davies</h3>
                        <p>
                            <b>Content Evacuation in Inter-DC Optical Networks Under Post-Disaster Cascading Failures </b>

                        </p>
                        <p>
                            <b>Zhuotong Li</b>; Zhuotong Li; Memedhe Ibrahimi; Yongli Zhao; Biswanath Mukherjee; Jie Zhang; Massimo Tornatore
                        </p>
                        <p><b>Optical Routing with Binary Optimisation and Quantum Annealing </b>
                        <p>
                            <b>Ethan Davies</b>; Ethan Gareth Davies; Darren Banfield; Vlad Cărare; Ben Weaver; Catherine White; Nigel Walker
                        </p>


</p>
                    </div>
                    </Grid>
                </Grid>



            </div>


            <h1><strong>Welcome</strong></h1>

            <p>
            The 28th International Conference on Optical Network Design and Modelling (ONDM 2024) will be organized by UC3M, Madrid, Spain, from May 6th to May 9th, 2024. The conference is expected to be held on site unless a situation arises that makes travel inadvisable.
            </p>
            The ONDM 2024 Conference will take place at the Campus of Puerta de Toledo, of the University Carlos III de Madrid.

            <p>
            ONDM 2024 is planned to include workshops, keynotes, tutorials, and invited talks, as well as high-quality contributed talks. The conference is expected to be highly interactive and to be a means to disseminate active and emerging areas of optical networking.
            </p>

            <p>
            Following the tradition of past ONDM conferences, ONDM 2024 will address cutting-edge research in established areas of optical networking and their adoption in support of a wide variety of new services and applications. This includes the most recent trends such as 5G and beyond; data-centre networking; Internet of things; cloud/edge computing; content delivery; big data, data analytics, network telemetry, and real-time monitoring; autonomic networking; artificial intelligence / machine learning assisted networks; visible light networks; and quantum secured networks.
            </p>

            <p>
            ONDM 2024 will be a great opportunity for sharing your latest academic and industrial research on optical networking.
            </p>

            <p>
            Looking forward to receiving your submissions, and to your participation!
            </p>

            <p><strong>David Larrabeiti-López</strong></p>

            <p><strong>ONDM 2024 General Chair</strong></p>
           
            <u>ondm2024@uc3m.es</u>

        </Box>
    )


}
