import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function Keynotes(){

    const location = useLocation();
    
    const refs = [
        useRef(null), useRef(null), useRef(null), useRef(null)
    ]

    const ids = ["fernandez", "maier", "bennett", "wong"];

    const scrollToElement = (refIndex) => {
        const {current} = refs[refIndex];   
        if (current !== null){
            current.scrollIntoView({behavior: "smooth"})
        }
    }

    useEffect(() => {
        if(location.hash !== ""){
            let hash = location.hash.split("#")[1]
            if(ids.includes(hash)){
                let index = ids.indexOf(hash)
                scrollToElement(index)
                setTimeout(() => {
                    scrollToElement(index)
                }, 500);
            }
        }
        // eslint-disable-next-line
    }, [location]);

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "200px"}}>
            <h1><strong>Keynotes</strong></h1>
            <KeynoteAuthor
                keynote_id="fernandez"
                refs={refs[0]}
                author="Juan Pedro Fernandez-Palacios"
                title="Next Generation Coherent Optical Pluggables: Challenges and Guidelines for Telco Networks"
                affiliation="Telefonica CTIO, Madrid, Spain. Email: juanpedro.fernandez-palaciosgimenez@telefonica.com"
                abstract="The evolution of coherent optical pluggables to small form factors such as 400ZR+ is opening the door for packet and optical convergence in Telco networks. Current commercial implementations are fulfilling the physical requirements for most point to point links over metro and regional ROADM based networks. However, it is important to note that while the technology is ready for adoption, some gaps in interoperability and self-automation need to be addressed. In many scenarios, achieving full system-level integration is the main technical challenge to be overcome:
                          Challenge 1: Assess the feasibility of integrating 400 Gbps and 100Gbps DCO QSFP pluggable transceivers into existing DWDM networks and identify any required hardware. 
                          Challenge 2: Effectively manage a diverse and extensive network that includes DCO pluggable transceivers on third-party equipment and existing DWDM transport infrastructure and management systems. In a multi-vendor environment, the challenge is one of considerable complexity.
                          Challenge 3: The process of adopting open and more integrated network architectures, while maintaining technological limitations is fundamental to realising the financial benefits of the integrated model, as is tackling the non-technical challenges associated with the adoption of such technologies."
                photo="/portraits/Juan.jpg"
                bio="Juan Pedro Fernández-Palacios received the MS in Telecommunications Engineering from Polytechnic University of Valencia in 2000. In Sept. of 2000 he joined Telefonica I+D where his research activities where focused on the design of new data and control plane architectures for IP over optical networks. He is author of 6 patents filled in Europe and US and more than 70 publications in conferences and journals. He was coordinator of two European research projects on optical transport networks (MAINS and IDEALIST) between 2011 and 2014. In 2013 he joined the Telefonica Global CTO office as Head of Transport. In 2016, he also took this position in Telefonica-O2 Germany. From June 2017 to March 2020 he was in charge of the Integrated Transport Centre, a global organization in Telefonica in charge of defining the strategic network planning for IP, DWDM, MW and satellite networks in Argentina, Chile, Peru, Colombia and Brazil. From 2019 he is leading Telefonica iFUSION project for common and vendor agnostic Transport SDN deployment in IP, optical and MW networks within Telefonica Group. From 2021 he is also co-chairing the MUST Group within the Telecom Infra Project."
            />

            <KeynoteAuthor
                keynote_id="maier"
                refs={refs[1]}
                author="Prof. Martin Maier"
                title="6G and Onward to Next G: From Society 5.0 to Symbiotic INTERBEING"
                affiliation="Optical Zeitgeist Laboratory; Institut National de la Recherche Scientifique (INRS), Montreal, QC, H5A 1K6 Canada. Email: martin.maier@inrs.ca"
                abstract="In a wide range of mobile application fields, including remote healthcare, autonomous/assisted driving, entertainment, and industry automation, very low latency and ultra-high reliability are key
                          for realizing immersive tactile Internet applications such as robotic teleoperation. Some of these use cases do not necessarily require mobility all the time and thus can be carried out in fixed
                          broadband network environments leveraging on existent optical fiber infrastructures. Hence, next-generation mobile networks need to be fully converged networks, where different fixed and
                          mobile access technologies can be flexibly selected while sharing core network functionalities, leading to latency and reliability improvements. The future 3D spatial Internet will be about being 
                          inside the Internet rather than simply looking at it from a phone or computer screen. It will surround us both visually and socially and will radically reshape society by realizing the fusion of
                          digital and real worlds across all dimensions created and delivered by non-traditional converged service platforms of future 6G and Next G networks, where developers do not hesitate to use
                          technologies from as many disciplines as possible. According to NSF, Next G research is more than 6G in that it includes but is not limited to cellular technologies. In fact, according to Next G
                          Alliance’s roadmap to 6G, there exists a unique opportunity to explore the symbiotic relationship between technological and human evolution. In this keynote, we present our recent work on
                          Japan’s vision of Society 5.0 for an Internet as if people mattered and then outline our latest ideas on the symbiosis of INTERnet and human BEING, giving rise to the powerful concept of
                          INTERBEING – a word that is not in the dictionary yet – benefitting from not only emerging generative AI but also nature’s more-than-human intelligence."
                photo="/portraits/Maier.jpg"
                bio="Martin Maier is a full professor with the Institut National de la Recherche Scientifique (INRS), Montréal, Canada. He was educated at the Technical University of Berlin, Germany, and received
                     MSc and PhD degrees both with distinctions (summa cum laude) in 1998 and 2003, respectively. In 2003, he was a postdoc fellow at the Massachusetts Institute of Technology (MIT), Cambridge,
                     MA. He was a visiting professor at Stanford University, Stanford, CA, 2006 through 2007. He was a co-recipient of the 2009 IEEE Communications Society Best Tutorial Paper Award. Further, he was
                     a Marie Curie IIF Fellow of the European Commission from 2014 through 2015. In 2017, he received the Friedrich Wilhelm Bessel Research Award from the Alexander von Humboldt (AvH)
                     Foundation in recognition of his accomplishments in research on FiWi-enhanced mobile networks. In 2017, he was named one of the three most promising scientists in the category “Contribution to
                     a better society” of the Marie Skłodowska-Curie Actions (MSCA) 2017 Prize Award of the European Commission. In 2019/2020, he held a UC3M-Banco de Santander Excellence Chair at Universidad
                     Carlos III de Madrid (UC3M), Madrid, Spain. Recently, in December 2023, he was awarded with the 2023 Technical Achievement Award of the IEEE Communication Society (ComSoc) Tactile Internet
                     Technical Committee for his contribution on 6G/Next G and the design of Metaverse concepts and architectures as well as the 2023 Outstanding Paper Award of the IEEE Computer Society Bio-Inspired Computing STC for his contribution on the symbiosis between INTERnet and Human BEING (INTERBEING)."
            />

            <KeynoteAuthor
                keynote_id="bennett"
                refs={refs[2]}
                author="Geoff Bennett"
                title="Optical Technologies for Terabit Networking"
                affiliation="Infinera Corporation. Email: gbennett@infinera.com"
                abstract="The modern internet, private IP networks, government and research networks, private and public cloud networks…all of them depend on optical capacity as the oxygen that allows them to function.  As their demand expands so the supply of capacity must follow.
                          Optical transmission has a long history of meeting these demands with a growth in capacity that meets the growth in user demands.  At different stages in this evolution, different technologies have helped with this scaling.  For examples, at one point in time perhaps it makes most sense to scale wavelength data rates, while at other points it make more sense to increase the number of wavelengths that travel through the fiber.  Dense Wavelength Division Multiplexing (DWDM) has been successful because it allows us to make the right economic decisions about scalability at the right time.
                          In this keynote I will briefly cover the timeline of capacity evolution in terrestrial and submarine networks up until today.  And the importance of this moment in time is that the optical industry is facing a limit in fiber pair capacity imposed by basic physics, which is sometimes dubbed “The Shannon Limit”.  The physics behind this limit have been understood since the late 1940s, and have been applied to optical communications along with the appropriate adjustments.
                          The Shannon Limit obliges us to think differently about capacity expansion for future optical communications and I will explain the current thinking on “where we go next for capacity” in both terrestrial and submarine networks.
                          Options can apply to existing fiber in the ground (eg. Super-C, C+L and Super-C + Super-L, as well as other fiber bands), or to new fiber types such as multicore and hollow core fibers.  In submarine networks which, in contrast to terrestrial networks, are power-constrained we see a separate roadmap based about Space Division Multiplexing (SDM) cable architectures.  But here we see a different set of issues and it is useful to consider how we can maintain common transponder evolution roadmaps for both terrestrial and submarine applications."
                photo="/portraits/Geoff.jpg"
                bio="Geoff Bennett is the Director of Solutions & Technology for Infinera, a leading manufacturer of Intelligent Transport Network solutions.  He has over 35 years of experience in the data communications industry, including IP routing with Proteon and Wellfleet; ATM and MPLS experience with FORE Systems; and optical transmission and switching experience with Marconi, where he held the position of Distinguished Engineer in the CTO Office.  Geoff is a frequent conference speaker, and is the author of “Designing TCP/IP Internetworks”, published by VNR."
            />
 
            <KeynoteAuthor
                keynote_id="wong"
                refs={refs[3]}
                author="Elaine Wong"
                title="The implementation of machine intelligence in next-generation access networks— rationale, challenges and emerging solutions"
                affiliation="University of Melbourne, Australia. Email: ewon@unimelb.edu.au"
                abstract="Access networks are envisioned to become increasingly complex as they support more and more diverse and immersive services, each with a different capacity, latency, and reliability need. While machine learning has been touted as a silver bullet that will intelligently manage network operations and resources to meet these demands, as it had been anticipated for core and metro networks, there exist various challenges that need to be addressed to progress machine learning models from research to production. We first aim motivate the continued push to advance access networks and rationalize the use of machine learning in these networks. We then highlight the challenges that are especially amplified due to the traffic dynamicity and heterogeneity, data scarcity, and computation-resource constraints of optical access networks. We discuss emerging approaches that are being explored to address challenges, such as concept drift, explainability and trust."
                photo="/portraits/Eliane_Wong.jpg"
                bio="Elaine Wong received her Ph.D. (2002) degree in Electrical Engineering from the University of Melbourne, Australia. She is a Redmond Barry Distinguished Professor and is currently Pro Vice Chancellor (People and Equity) of the University.  Her current research interests include low-latency communication networks and prescriptive analytics to facilitate human-to-machine applications over the Tactile Internet. Elaine currently serves on the ARC College of Experts, the IEEE Photonics Society Board of Governors, and is Chair of the IEEE Communication Society Optical Network Technical Community.  She is Technical Editor of IEEE Network, General Chair of Opto Electronic Communication Conference (OECC) 2024, and General Chair of Optical Fiber Communication Conference (OFC) 2025. She has previously served on many editorial boards including IEEE/OSA Journal of Optical Communications and Networking, IEEE/OSA Journal of Lightwave Technology, and OSA Journal of Optical Networking."
            />
        </Box>
    )
}


function KeynoteAuthor({author, affiliation, title, abstract, photo, bio, keynote_id, refs}){
    return(
        <div style={{marginTop: 30}} id={keynote_id} ref={refs}>

            <h2>{title}</h2>
            <Grid container>

                <Grid item xs={4}>
                <img src={photo} alt={`${author}'s portait`} style={{width: "90%", margin: "auto", borderRadius: 5, marginTop: 10}}/>
                </Grid>
                <Grid item xs={8}>
                <div>
                    <b>{author}</b>, {affiliation}
                </div>
                <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' }}}>
                <div style={{textAlign: "justify", marginTop: 12}}>
                    {abstract}
                </div>
                </Grid>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item md={9} xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                <div style={{textAlign: "justify", marginTop: 12}}>
                    {abstract}
                </div>
                </Grid>
            </Grid>
            
            <div style={{textAlign: "justify", marginTop: 20}}>
                {bio}
            </div>
        
        
        </div>

    )
}
