import { Box } from "@mui/system";
export default function Author(){

    return(
        <Box sx={{maxWidth: "90%", margin:"auto"}}>
            <h1><strong>About</strong></h1>

            <h2>Previous Conferences</h2>
            <p>2023, May 08–11, Coimbra, Portugal: <a href="https://ondm2023.inescc.pt/">https://ondm2023.inescc.pt/</a></p>
            <p>2022, May 16–19, Warsaw, Poland: <a href="https://ondm2022.com/">https://ondm2022.com/</a></p>
            <p>2021, June 28–July 01, Gothenburg, Sweden: <a href="https://ondm2021.chalmers.se">https://ondm2021.chalmers.se</a></p>
            <p>2020, May 18-21, Castelldefels, Barcelona, Spain: <a href="https://ondm2020.cttc.cat/">https://ondm2020.cttc.cat/</a></p>
            <p>2019, May 13-16, Athens, Greece</p>
            <p>2018, May 14-17, Dublin, Ireland: <a href="https://ondm2018.scss.tcd.ie/">https://ondm2018.scss.tcd.ie/</a></p>
            <p>2017, May 15-17, Budapest, Hungary: <a href="http://www.ondm2017.hte.hu/">http://www.ondm2017.hte.hu/</a></p>
            <p>2016, May 09-12, Cartagena, Spain: <a href="http://ondm2016.upct.es/">http://ondm2016.upct.es/</a></p>
            <p>2015, May 11-14, Pisa, Italy: <a href="http://ondm2015.sssup.it/">http://ondm2015.sssup.it/</a></p>
            <p>2014, May 19-22, Stockholm, Sweden: <a href="https://ondm2014.ict.kth.se/">https://ondm2014.ict.kth.se/</a></p>
            <p>2013, April 16-19, Brest, France: <a href="http://conferences.telecom-bretagne.eu/ondm2013/">http://conferences.telecom-bretagne.eu/ondm2013/</a></p>
            <p>2012, April 17-20, Colchester, United Kingdom</p>
            <p>2011, February 08-10, Bologna, Italy</p>
            <p>2010, February 01-03, Kyoto, Japan</p>
            <p>2009, February 18-20, Braunschweig (Brunswick), Germany</p>
            <p>2008, March 12-14, Vilanova i la Geltru, Spain</p>
            <p>2007, May 29-31, Athens, Greece: <a href="http://www.ondm2007.gr/">http://www.ondm2007.gr/</a></p>

            <p>2006, May 22-24, Copenhagen, Denmark</p>
            <p>2005, February 07-09, Milano, Italy</p>
            <p>2004, February 02-04, Ghent, Belgium</p>
            <p>2003, February 03-05, Budapest, Hungary</p>
            <p>2002, February 04-06, Torino, Italy</p>
            <p>2001, February 05-07, Vienna, Austria</p>
            <p>2000, February 07-08, Athens, Greece</p>
            <p>1999, February 08-09, Paris, France</p>
            <p>1998, February 09-11, Roma, Italy</p>
            <p>1997, February 24-25, Vienna, Austria</p>

        </Box>
    )


}
