

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'name', label: '', minWidth: 40 },
  { id: 'fulFee1', label: 'Early registration (until April 8)', minWidth: 40 },
  { id: 'fulFee2', label: 'Late registration (after April 8)', minWidth: 40 },
];

/*const columns = [
  { id: 'name', label: '', minWidth: 40 },
  { id: 'redFee1', label: 'Reduced fee*', minWidth: 40 },
  { id: 'fulFee1', label: 'Early registration (until April 8)', minWidth: 40 },
  { id: 'redFee2', label: 'Reduced fee*', minWidth: 40 },
  { id: 'fulFee2', label: 'Late registration (after April 8)', minWidth: 40 },
];*/

/*function createData(name, redFee1, fulFee1, redFee2, fulFee2) {
  return { name, redFee1, fulFee1, redFee2, fulFee2 };
}*/

function createData(name, fulFee1, fulFee2) {
  return { name, fulFee1, fulFee2 };
}

const rows = [
  createData('Author', '750.00 €', '850.00 €'),
  createData('Author & IFIP/IEEE', '650.00 €', '750.00 €'),
  createData('Author student', '650.00 €', '750.00 €'),
  createData('Author student & IFIP/IEEE', '575.00 €', '675.00 €'),
  createData('Attendee', '650.00 €', '750.00 €'),
  createData('Attendee & IFIP/IEEE', '550.00 €', '650.00 €'),
  createData('One-day Workshop (May 7) speakers', '150.00 €', '175.00 €'),
  createData('One-day Workshop & IFIP/IEEE (May 7) speakers', '135.00 €', '145.00 €'),
  createData('Accompanying person (Social program)', '150.00 €', '150.00 €'),
  createData('Extra Gala dinner', '100.00 €', '100.00 €'),
  createData('Extra Welcome Reception', '75.00 €', '75.00 €')
];

export default function FeeTable() {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(15);

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                sx={{fontWeight: "900"}}
                  key={column.id}
                  align="center"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align="center" sx={{fontWeight: "900"}}> 
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

    </Paper>
  );
}
