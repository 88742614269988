import { Box } from "@mui/system";

import SponsorTable from "../components/SponsorTable";

export default function Sponsor(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto"}}>
            <h1><strong>Become a Sponsor</strong></h1>
            <p>
            A limited number of Platinum, Gold, Silver, and Bronze sponsorship opportunities are available on a first-come, first-served basis.
            </p>
            <p>
            These prestigious packages offer a unique opportunity to highlight someone’s brand and to position an organization as a leader and innovator in technology development and commercialization, staying at the forefront of Future Optical Networks.
            </p>
            <p>
                Additionally, a great opportunity of becoming a sponsor of the Best Paper Award is also available.
            </p>

            <p>

            Should the conference be forced to run as a virtual event a 40% reduction can be applied upon request to all the sponsorship levels except for the Best Paper Award fee.
            </p>

            <p>
                For more information or specific questions please e-mail us at: <u>ondm2024@uc3m.es</u>
            </p>

            <SponsorTable/>
        </Box>
    )


}
