import { Box } from "@mui/system";

import FeeTable from "../components/FeeTable";

export default function Registration(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", minHeight: "2000px"}}>
            <h1><strong>Registration</strong></h1>

            <div style={{marginBottom: 30}}>
            To register click <a href="https://www.flowte.me/storefront/uc3m-">here</a>. There, you will have to select ONDM2024 from the list of available UC3M conferences.
            
            <div>
            Notice that you will be redirected to a <a href="https://flowte.me">flowte.me</a> secure credit card payment gateway. 
            </div>
            </div>
        {
            <div style={{width: "100%"}}>
                <FeeTable></FeeTable>

            </div>

         /* <p>
            *) For IEEE members
            </p>


            <p>
            #) One day Workshop (non-author) includes an attendee package,  lunch and coffee breaks on May 10 (Gala Dinner excluded).
            </p>

            <p>
            All fees (except the Extra Gala Dinner,  Accompanying person and One day Workshop) include an attendee package, lunches, coffee breaks, and the social program.
            </p>

            <p>
            All fees are in Euros and include the applicable VAT rate.
            </p>

            <p>
            The social program includes a Welcome lunch (on May 8), a walking guided tour (about 2h) of the old part of the city of Coimbra (including the exterior of the University) followed by an Informal dinner (on May 9), and a Gala dinner (on May 10).
            </p>

            <p>
            Cancellations fees will apply (50% of the registration value) for cancellations before April 15. After that date, no refunds will be possible.
            </p>

            <p>
            For each accepted regular or invited paper, at least one of the authors is required to be registered at the applicable rate until March 20. For authors with multiple accepted papers, one registration is valid only for one accepted paper. You will get a receipt from the organizers after you pay for the registration.
            </p>

            <p>

            Whether you are required to have a Visa or not to travel to Spain, the letter of invitation provided by the conference organizers may be useful for agents at Ports of Entry to determine that a person is a legitimate visitor that intends to attend the conference. Per the conference policy, only duly-registered participants may get an invitation letter. Should you need a letter of invitation to apply for your visa, this needs to be requested on the registration website when registering and paying for the conference.
            </p>
*/}
        </Box>
    )


}
