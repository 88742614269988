import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@mui/material/Grid";
import '@fontsource/roboto/700.css';
import { Box, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

function SessionSlide({photo, author, title, sessionType, link, affiliation}){

  const navigate = useNavigate();

  return(
    <Box sx={{margin: "auto", mx: 1, maxWidth:"100vw", height: {xl: "300px", md: "280px", xs: "max(23vh, 200px)"}}}>
      <Grid container direction={"row"} spacing={0}>
        <Grid xs={3}>
        <div style={{display: "flex"}}>
          <div style={{
            width: "100%",
            height: "90%",
            margin: 0,
            overflow: "hidden"
          }}>
            <img src={photo} alt={`${author}'s portait`} style={{width: "90%", margin: "auto",  marginTop: 0}}  onClick={() => navigate(link)}/>
          </div>
        </div>
        </Grid>
        <Grid xs={9}>
        <div style={{width: "100%", margin: "auto", textAlign: "center"}}>
        <Typography component="div" sx={{  typography: { md: 'h5', sm: 'h6' }, padding: "6px", textAlign: "center", backgroundColor: "#000E77dd", color:"white"}}>{sessionType}</Typography>
          <div  onClick={() => navigate(link)}>
          <Typography component="h5" sx={{marginTop: 0,  typography: { xl: 'h4', md: 'h6', sm: 'h6' }}}>{title}</Typography>
          <Typography component="h3" sx={{marginTop: 0,  typography: { xl: 'h5', md: 'h6', sm: 'body2' }, marginTop: { md: 4, sm: 0}}}>{author}</Typography>
          <Typography component="h5" sx={{marginTop: 0,  typography: { xl: 'h6', md: 'body1', sm: 'body2' }}}>{affiliation}</Typography>
          </div>
        </div>

        </Grid>
      </Grid>
    </Box>
  )
}

export default function SessionsSlider() {
  let settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000
  };
  return (
    <Slider {...settings}>
      <SessionSlide
        author="Juan Pedro Fernandez-Palacios"
        title="Next Generation Coherent Optical Pluggables: Challenges and Guidelines for Telco Networks"
        photo="/portraits/Juan.jpg"
        affiliation="Telefonica CTIO"
        sessionType="Keynote"
        link="/program/keynotes"
      />
      <SessionSlide
        author="Martin Maier"
        title="6G and Onward to Next G: From Society 5.0 to Symbiotic INTERBEING"
        photo="/portraits/Maier.jpg"
        affiliation="Optical Zeitgeist Laboratory; Institut National de la Recherche Scientifique (INRS)"
        sessionType="Keynote"
        link="/program/keynotes"
      />
      <SessionSlide
        author="Geoff Bennett"
        title="Optical Technologies for Terabit Networking"
        photo="/portraits/Geoff.jpg"
        affiliation="Infinera Corporation"
        sessionType="Keynote"
        link="/program/keynotes"
      />
      <SessionSlide
          author="Elaine Wong"
          title="The implementation of machine intelligence in next-generation access networks— rationale, challenges and emerging solutions"
          photo="/portraits/Eliane_Wong.jpg"
          affiliation="University of Melbourne"
          sessionType="Keynote"
          link="/program/keynotes"
      />
      <SessionSlide
        author="Qunbi Zhuge"
        title="Autonomous driving of optical networks: from digital twin modeling to intelligent network control"
        photo="/portraits/Zhuge.jpg"
        affiliation="Department of Electronic Engineering, Shanghai Jiao Tong University"
        sessionType="Tutorial"
        link="/program/tutorials"
      />
      <SessionSlide
        author="Luis M. Contreras"
        title="Transport Slicing from the perspective of the IETF"
        photo="/portraits/Contreras.jpeg"
        affiliation="Technology Expert, Telefónica Innovación Digital"
        sessionType="Tutorial"
        link="/program/tutorials"
      />
      <SessionSlide
        author="Davide Bacco"
        title="From Quantum Cryptography to the Quantum Internet"
        photo="/portraits/Davide.jpg"
        affiliation="Associate Professor University of Florence, co-founder Q.T.I srl"
        sessionType="Tutorial"
        link="/program/tutorials"
      />


    </Slider>
  );
}