import { Box } from "@mui/system";



export default function TravelInfo(){

    return(
        <Box sx={{maxWidth: "90%", margin:"auto"}}>
            <h1><strong>Travel Information</strong></h1>
            <h2>Visa Requiremetns</h2>
            <p>
            Spain is in the Schengen area (comprising 27 European countries), for which foreigners may need an entry visa. If you do need it, please make sure you apply for it well in advance before your departure.  
            In the Schengen area, you may travel freely between member countries. 
            For more information or questions contact the Local Organizing Committee. 
            </p>
           
            <h2>How to get from Madrid Airport to the city centre</h2>
            <p>
            You can get from Barajas Airport to Madrid by bus, train, metro, or taxi. Buses will take you to different points of the city (depending on the route) in 40 minutes and for 5 €. A journey by metro will take up to 20 minutes – the route is Madrid Airport-Nuevos Ministerios station (1.5-2 € for a ticket). RENFE train will get you to the city in 25 minutes and for 2.6 €. Besides, you can also get from Madrid Airport to the city centre in less than 25 minutes by taxi – the journey cost is from 37 €. The approximate direct distance between Airport and Madrid city centre is 13 km, and about 18 km – by the route.
            </p>
            <p>
            Public transport in the capital of Spain is available from early morning until late at night, but not 24/7, though this travel option is the cheapest. There is usually no problem buying tickets in case you speak English. Note that on buses tickets can be bought from the driver, and on metro you can buy a travel card only with the help of special machines (it is advisable to have some change with you). At nighttime, it is more comfortable to get to the city centre by taxi.
            </p> 

            <h2>Getting around Madrid</h2>
            <p>The Tourist Ticket (also known as the Tourist Travel Pass) can be used on all types of public transport in the Region of Madrid. Allowing you to take unlimited trips within your chosen timeframe, it is a practical and inexpensive way to get around Madrid.</p>

            <p>Passes are valid for 1, 2, 3, 4, 5, or 7 days and for two zones (A and T).</p>

            <p>Are you going to stay in the city centre and do you plan to get around there? Then Zone A is the right option for you since it covers these services:</p>

            <ul>
              <li>Use of the Metro in zone A (City of Madrid) – including the 3 € airport supplement,</li>
              <li>All blue EMT city buses (except the yellow Airport Express shuttle),</li>
              <li>Renfe Cercanías commuter trains covering zones 0 and A,</li>
              <li>Metro Ligero light rail train ML1.</li>
            </ul>

            <p>Are you going to be in Madrid for several days and are also planning to visit the surrounding area? Then Zone T is the right option for you, since it covers:</p>

            <ul>
              <li>The entire Metro network,</li>
              <li>All blue city buses (except the Airport Express shuttle) and the green intercity buses in the Region of Madrid, including services to Guadalajara and Toledo. This pass encompasses all 8 zones, from A to E2 (6 zones in the Region of Madrid and 2 in Castilla La Mancha),</li>
              <li>All Renfe Cercanías commuter trains (zones 0, A, B1, B2, B3, C1, C2),</li>
              <li>Metro Ligero light rail trains ML1, ML2 and ML3, and the ML4 Parla tramway.</li>
            </ul>

            <p>Zone T is the recommended option if you’re planning on making day trips to places like El Escorial, Aranjuez, or Alcalá de Henares.</p>

            <p><strong>IMPORTANT:</strong> It cannot be used on high-speed trains (including the AVE to Guadalajara and the Avant to Toledo) nor Renfe Operadora regional trains. It is also not valid on Tourist Trains.</p>

            <p>All Tourist Travel Passes are pre-loaded onto the Tarjeta Multi, a contactless, transferable smartcard that is valid for 10 years and which is free when you purchase the 1, 2, 3, 5, or 7-day Tourist Travel Pass. Once the Tourist Travel Pass expires, you can top up the smartcard with pay-per-ride tickets.</p>

            <p>You can purchase the pass at:</p>

            <ul>
              <li>All Metro stations (including the airport stations Aeropuerto T1-T2-T3 and Aeropuerto T4) from ticket machines that have a red sticker saying Tarjeta MULTI Disponible AQUÍ.</li>
              <li>The entrance to the Cercanías (commuter train) Aeropuerto T4 station</li>
              <li>The main office of the Madrid Regional Transport Consortium: Plaza del Descubridor Diego de Ordás, 3.</li>
              <li>Estancos (tobacco shops) and from other authorized retailers.</li>
              <li>All Metro Ligero (ML1, ML2, ML3, and ML4) stations.</li>
           </ul>

           <p><strong>Where to top up your travel card:</strong></p>
 
           <ul>
             <li>Automated ticket machines found in all Cercanías train stations</li>
             <li>Automated ticket machines found in Metro de Madrid Stations and ML1 stations</li>
             <li>Automated ticket machines found in the city’s light railway (Metro Ligero) Stations (ML2 and ML3)</li>
             <li>Information points at Transport Hubs: 10-trip bus passes (bonobús) for urban and intercity services and tourist travel cards</li>
             <li>Estanco kiosks and other authorized retailers: 10-trip travel cards (Metrobús and intercity bonobús) and tourist travel cards</li>
             <li>Travel Card App</li>
          </ul>
          <p>If you do not wish to acquire a Tourist Travel Pass, you can buy the Tarjeta Multi (2.5 €) and simply top it up with pay-per-ride tickets.</p>
          
        </Box>
    )


}
