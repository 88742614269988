import Typography from '@mui/material/Typography';
import './App.scss';

import { Fragment } from 'react';

import ResponsiveAppBar from './components/ResponsiveAppBar';
import { Routes, Route, useNavigate, NavLink } from "react-router-dom";
import { Grid } from '@mui/material';

import Home from './pages/Home';
import Author from './pages/Author'
import About from './pages/About';
import Committees from './pages/Committees';
import Registration from './pages/Registration';
import Sponsor from './pages/Sponsor';
import TravelInfo from './pages/TravelInfo';
import Venue from './pages/Venue';
import Program from './pages/Program';
import TechnicalProgram from './pages/TechnicalProgram';
import Card from '@mui/material/Card';
import ImageSlider from './components/ImageSlider';
import Keynotes from './pages/Keynotes';
import InvitedSpeakers from './pages/InvitedSpeakers';
import Tutorials from './pages/Tutorials';
import Workshops from './pages/Workshops';
import Twitter from '@mui/icons-material/Twitter';
import LinkedIn from '@mui/icons-material/LinkedIn';
import IconButton from '@mui/material/IconButton';  
import Stack from '@mui/material/Stack';
import SocialEvents from './pages/SocialEvents';
import { Telegram } from '@mui/icons-material';

function SideBarItem({title, content}){
  return(
    <Card sx={{ m: 1}}>
    <Typography component="div" variant="h6" style={{ padding: 3, textAlign: "center", backgroundColor: "#000E77dd", color:"white"}}>
    {title}
  </Typography>

      <div style={{padding:10}}>
        {content}
      </div>
    </Card>
  )
}


function App() {
  const navigate = useNavigate();

  return (
    <div className="App">
        {/*<div style={{textAlign: "center", position: 'absolute', zIndex: 1, height: "450px", width: "100%"}}>

        <h1>ONDM 2024</h1>
        <h3>28TH INTERNATIONAL CONFERENCE ON OPTICAL NETWORK DESIGN AND MODELLING </h3>
        <h3>6 - 9 May 2024, Madrid, Spain</h3>
        <div style={{width: "clamp(50px, 70%, 260px)", margin: "auto"}}>
        <Box style={{height: "40px"}} display={{sm: "flex", xs: "none"}}>

        </Box>
        <img src="logo.png" alt="ONDM 2024 Logo" width="100%"/>
        </div>

        </div>*/}

        <div className="imageHeader" style={{margin:"auto", top: "0px", position: "sticky", zIndex: 0}}>

        <ImageSlider/>
        </div>

        <div style={{position: "relative", zIndex: 2, background: "#f8f8f8"}}>
        <ResponsiveAppBar callback={(a) => navigate(a)}/>

        <div className="contentBlock">
        <Grid container spacing={2} marginBottom={10}>
          <Grid item md={9} sm={12} xs={12}>
          <Fragment>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/home" element={<Home/>}/>
              <Route path="/authorInfo" element={<Author/>}/>
              <Route path="/registration" element={<Registration/>}/>
              <Route path="/program" element={<Program/>}/>
              <Route path='/program/technical-program' element={<TechnicalProgram/>}/>
              <Route path='/program/keynotes' element={<Keynotes/>}/>
              <Route path='/program/invited-speakers' element={<InvitedSpeakers/>}/>
              <Route path='/program/tutorials' element={<Tutorials/>}/>
              <Route path='/program/workshops' element={<Workshops/>}/>
              <Route path="/committees" element={<Committees/>}/>
              <Route path="/sponsor" element={<Sponsor/>}/>
              <Route path="/travelInfo" element={<TravelInfo/>}/>
              <Route path="/venue" element={<Venue/>}/>
              <Route path="/socialEvents" element={<SocialEvents/>}/>
              <Route path="/about" element={<About/>}/>
            </Routes>
          </Fragment>
          </Grid>
          {/* COLUMN */}
          <Grid item md={3} sm={12} xs={12}>
            <SideBarItem title={"IMPORTANT DATES"} content={
              <>
                  <div style={{textDecoration: "line-through"}}>
                    <b>Extended deadline</b>: 27 January 2024
                  </div>
                  <div style={{textDecoration: "line-through"}}>
                    <b>Firm deadline</b>: 5 February 2024 (<span style={{color: "red"}}>final!!</span>) 
                  </div>
                  <div style={{textDecoration: "line-through"}}>
                    <b>Acceptance notification</b>: 20 March 2024 
                  </div>
                  <div style={{textDecoration: "line-through"}}>
                  <b>Programme release</b>: 05 April 2024
                  </div>
                  <div style={{textDecoration: "line-through"}}>
                    <b>Camera-ready submission</b>: 07 April 2024
                  </div>
                  <div>
                    <b>Poster service submission</b>: 28 April 2024
                  </div>
                  <div>
                    <b>Conference date</b>: 06-09 May 2024
                  </div>
              </>
            }/>

            <SideBarItem title={"LATEST NEWS"} content={
              <>

                  <div>
                    <b>Best Paper Award competition rules</b>: 18 April 2024, <a href="/Rules_BPA_BSPA_ONDM_2024.pdf" download>available here</a>
                  </div>

                  <div>
                    <b>Social media and Telegram Channel:</b> <a href="https://bio.link/ondm2024" target="_blank" rel="noopener noreferrer">click here</a> 
                  </div>

                  <div>
                    <b style={{ textDecoration: "underline", cursor: "pointer" }}>
                      <a href="https://www.ieee.org" target="_blank" rel="noreferrer" style={{ color: "black", textDecoration: "underline" }}>
                        Technically co-sponsored by IEEE
                      </a>
                    </b>
                  </div>
              </>
            }/>

            <SideBarItem title={"PLATINUM SPONSOR"} content={
              <div style={{textAlign: "center"}}>
                  <a href="https://www.huawei.com/en/" target="_blank" rel="noopener noreferrer">
                  <img src="/platinum_sponsor_huawei.png" alt="Platinum Sponsor" style={{width: "95%", margin: "auto", marginTop: 25, marginBottom: 25}}/>
                  </a>
              </div>
            }/>

            <SideBarItem title={"GOLD SPONSOR"} content={
              <div style={{textAlign: "center"}}>
                  <a href="https://www.adtran.com" target="_blank" rel="noopener noreferrer">
                  <img src="/gold_sponsor_Adtran_300dpi.png" alt="Adtran Gold Sponsor" style={{width: "80%", margin: "auto"}}/>
                  </a>
              </div>
            }/>
            
            <SideBarItem title={"GOLD SPONSOR"} content={
              <div style={{textAlign: "center"}}>
                  <a href="https://www.infinera.com/" target="_blank" rel="noopener noreferrer">
                  <img src="/Gold_Sponsor_Infinera_Logo.jpg" alt="Infinera Gold Sponsor" style={{width: "80%", margin: "auto"}}/>
                  </a>
              </div>
            }/>

                        
            <SideBarItem title={"GOLD SPONSOR"} content={
              <div style={{textAlign: "center"}}>
                  <a href="https://www.nokia.com" target="_blank" rel="noopener noreferrer">
                  <img src="/nokia.svg" alt="Nokia Gold Sponsor" style={{width: "70%", margin: "auto", marginTop: 15, marginBottom: 15}}/>
                  </a>
              </div>
            }/>

             <SideBarItem title={"SILVER SPONSOR"} content={
              <div style={{textAlign: "center"}}>
                  <img src="/uc3m.jpg" alt="Silver Sponsor" style={{width: "60%", margin: "auto"}}/>
                  <div>
                  <a href="/sponsor">Still available</a>
                  </div>
              </div>
            }/>  

              <SideBarItem title={"ORGANIZATION"} sx={{ marginBottom: "80px"}} content={
              <div style={{textAlign: "center"}}>
                  <img src="/uc3m.jpg" alt="UC3M logo, organization" style={{width: "70%", margin: "auto"}}/>
                  <div>
                  </div>
              </div>
            }/>
            
           <SideBarItem title={"TECHNICAL CO-SPONSORSHIP"} content={
             <>
              <div style={{textAlign: "center"}}>
                  <a href="https://www.ieee.org" target="_blank" rel="noopener noreferrer">
                  <img src="/ieee_advancing_tech.png" alt="Supporter" style={{width: "30%", margin: "auto"}}/>
                  </a>
              </div>
              <div style={{textAlign: "center"}}>
                  <a href="https://www.ieee.org" target="_blank" rel="noopener noreferrer">
                  <img src="/ieee_comsoc.png" alt="Supporter" style={{width: "30%", margin: "auto"}}/>
                  </a>
              </div>
              <div style={{textAlign: "center"}}>
                  <a href="https://www.ieee.org" target="_blank" rel="noopener noreferrer">
                  <img src="/ieee_photonics.png" alt="Supporter" style={{width: "30%", margin: "auto"}}/>
                  </a>
              </div>
              </>
            }/>

           <SideBarItem title={"SUPPORTED BY"} content={
              <div style={{textAlign: "center"}}>
                  <a href="https://www.ifip.org" target="_blank" rel="noopener noreferrer">
                  <img src="/ifip_logo.jpg" alt="Supporter" style={{width: "50%", margin: "auto"}}/>
                  </a>
              </div>
            }/>
           <SideBarItem title={"SOCIAL MEDIA LINKS"} content={
              <>
              <Stack direction="row" spacing={8} justifyContent="center"
                  alignItems="center" >
                <IconButton aria-label="twitter" onClick={() => window.open('https://twitter.com/ONDM2024', '_blank')}>
                  <Twitter style={{fontSize: 40}}/>
                </IconButton>

                <IconButton aria-label="linkedin" onClick={() => window.open('https://www.linkedin.com/groups/9810014/', '_blank')}>
                <LinkedIn style={{fontSize: 40}}/>
                </IconButton>

                <IconButton aria-label="telegram" onClick={() => window.open('https://t.me/+Bd3EAVR816I1ZGZk', '_blank')}>
                <Telegram style={{fontSize: 40}}/>
                </IconButton>

                </Stack>
                <a href="https://bio.link/ondm2024" target="_blank" rel="noopener noreferrer">
              <Stack justifyContent="center">
                <img src="/qr.png" alt="QR code for social media" style={{width: "70%", margin: "auto"}}/>
              </Stack>
                </a>
              </>
            }/> 
          </Grid>

        </Grid>
        </div>


        </div>
  
        
    </div>
  );
}

export default App;
