import { Box } from "@mui/system";

import { Iframe } from '../components/Iframe'

export default function Venue(){

    return(
        <Box sx={{maxWidth: "90%", margin:"auto"}}>
            <h1><strong>Venue</strong></h1>
            <h2>ONDM 2024 Conference</h2>

            <p>The ONDM 2024 meeting will take place at Campus Puerta de Toledo of University Carlos III de Madrid</p>

            <p>During the conference, lunch and coffee breaks will be served at the Campus Canteen Area on the conference building basement level (-1 Floor). The food consist of a series of sorted tapas (small dishes with savoury snacks). If you have any dietary restrictions, please contact us.</p>

            <h2>Address</h2>
            <p>
            Ronda de Toledo, 1, 28005 Madrid
            </p>
            <Box sx={{display: { sm: 'none', md: 'block' }}}>

            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2148.2423569532825!2d-3.7099562390484144!3d40.40722858480652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4227d124e99209%3A0xae3187b0fd8c9227!2sUniversity%20Carlos%20III%20of%20Madrid%20%7C%20Puerta%20de%20Toledo!5e0!3m2!1sen!2ses!4v1686925202248!5m2!1sen!2ses" 
            width="100%" height="500" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </Box>


            <h2>Recommended Hotels</h2>
            <p>Madrid city center is well connected with Metro, and the conference venue has "Puerta de Toledo" Metro Station 50m away, so hotels at Madrid city center can be reached in 20 minutes. In particular, the campus Metro line has very centric stations 4-stops-away like "Gran Via" , that hosts many touristic hotels. Other landmarks like "Plaza Mayor" or "Sol" are located at a walking distance. We recommend that you check the Metro connectivity from your hotel to the campus when booking. The next list contains the closest hotels to the conference venue that we recommend:</p>           
            
            <p><strong>RAFAELHOTELES ATOCHA ****</strong></p>
            <p>C. de Méndez Álvaro, 30, 28045 Madrid</p>
            <p>Phone +34 914 68 81 00</p>
            <p>email: rafaelhoteles@rafaelhoteles.com</p>
            <p>web: <a href="https://www.rafaelhoteles.com">https://www.rafaelhoteles.com</a></p>
            <p>PROMOCODE 12% available with the link: <a href="https://www.rafaelhoteles.com/en/ondm-2024.html">https://www.rafaelhoteles.com/en/ondm-2024.html</a></p>

            <p><strong>HOTEL VINCCI CAPITOL ****</strong></p>
            <p>C/ Gran Vía, 41, 28013 Madrid</p>
            <p>Phone +34 914 74 71 00</p>
            <p>email: capitol@vinccihoteles.com</p>
            <p>web: <a href="https://www.vinccicapitol.com">https://www.vinccicapitol.com</a></p>

            <p><strong>HOTEL VIA 66 ****</strong></p>
            <p>C/ Gran Vía, 66, 28013 Madrid</p>
            <p>Phone +34 915 50 42 99</p>
            <p>email: via66@vinccihoteles.com</p>
            <p>web: <a href="https://www.vinccivia66.com">https://www.vinccivia66.com</a></p>

            <p><strong>HOTEL PORCEL GANIVET ***</strong></p>
            <p>C. de Toledo, 111 - 113, 28005 Madrid</p>
            <p>Phone +34 913 65 36 00 / +34 858 890 505</p>
            <p>email: ganivet@hotelesporcel.com / reservas@hotelesporcel.com</p>
            <p>web: <a href="https://www.hotelganivet.com">https://www.hotelganivet.com</a></p>

            <p><strong>PETIT PALACE HOTELS</strong></p>
            <p>Phone +34 91 800 49 94</p>
            <p>email: reservas@hotelatelier.com</p>
            <ul>
            <li><a href="https://www.petitpalaceplazamayor.com/en/">Petit Palace Plaza Mayor****</a></li>
            <li><a href="https://www.petitpalaceposadadelpeine.com/en/">Petit Palace Posada del Peine****</a></li>
            <li><a href="https://www.petitpalacearenal.com/en/">Petit Palace Arenal***</a></li>
            <li><a href="https://www.petitpalacepuertadelsol.com/en/">Petit Palace Puerta del Sol***</a></li>
            </ul>


            <h2>Tourist Information About Madrid</h2>
            <p><strong>Cultural Marvels and Gastronomic Delights</strong></p>
            <p>Embark on a multifaceted exploration of Madrid, where cultural marvels seamlessly intertwine with vibrant excursions and gastronomic delights.</p>
            <p><u>Artistic Wonders</u></p>
            <p>Begin your journey at the esteemed Prado Museum, a relentless repository of European masterpieces. From Vel&aacute;zquez&apos;s enigmatic strokes to Goya&apos;s emotional tapestry, this cultural bastion demands unwavering attention. Adjacent, the Royal Palace unfolds regal history within opulent interiors, offering an immersive encounter with Spain&apos;s monarchical legacy.</p>
            <p><u>Football Passion</u></p>
            <p>Dive into Madrid&apos;s fervent football culture by venturing to the Santiago Bernab&eacute;u Stadium, home to the legendary Real Madrid. The pulsating energy of match day and the stadium&apos;s rich history underscore the city&apos;s deep-seated love for the beautiful game. Equally, explore the Civitas Metropolitano, the spirited arena of Atl&eacute;tico de Madrid, encapsulating the intense football rivalry that animates the city.</p>
            <p><u>Culinary Tapestry</u></p>
            <p>Navigate Madrid&apos;s gastronomic tapestry, where culinary excellence intertwines with cultural identity. Indulge in the savory depths of cocido madrile&ntilde;o, a traditional stew symbolizing the city&apos;s rich gastronomic heritage. Traverse the labyrinthine streets, discovering hidden gems in the form of tapas bars, each offering a distinctive flavor palette that augments the overall culinary experience.</p>
            <p><u>Beyond Madrid City</u></p>
            <p>Extend your cultural odyssey with a day trip to the captivating cities of Toledo and Segovia. Both picturesque and historically rich, these locales boast architectural wonders such as Toledo&apos;s medieval Alc&aacute;zar and Segovia&apos;s awe-inspiring aqueduct. The juxtaposition of history and beauty creates an unforgettable sojourn, complemented by the exploration of local culinary delights in these charming towns.</p>
            <p><u>Nature&apos;s Respite</u></p>
            <p>Amidst the cultural fervour, seek solace in Retiro Park, a fully-fledged retreat offering serene landscapes and the iconic Crystal Palace. This oasis allows participants to recharge amidst nature&apos;s embrace, complementing the academic intensity of the conference.</p>
            <p>This comprehensive guide ensures conference attendees unravel the multifaceted layers of Madrid, from cultural marvels within the city limits to football passion, culinary explorations, and scenic escapes to neighbouring historical gems, all contributing to an immersive and unforgettable experience.</p>
            <p><strong>Download maps and guides of Madrid</strong></p>
            <p><strong><a href="https://www.esmadrid.com/sites/default/files/mapa_turistico_madrid_22112023.pdf" target="_blank" rel="noopener noreferrer">Touristic map of iconic places in Madrid</a></strong></p>
            <p><strong><a href="https://www.esmadrid.com/sites/default/files/plano_metro_madrid_junio_2023.pdf" target="_blank" rel="noopener noreferrer">Subway network map</a></strong></p>
            <p><strong><a href="https://www.esmadrid.com/sites/default/files/mapa_restaurantescentenarios_madrid.pdf" target="_blank" rel="noopener noreferrer">Guide to where to eat in Madrid</a></strong></p>
            <p><strong><a href="https://www.esmadrid.com/sites/default/files/documentos/planoturisticodelosautobusesdemadrid_0.pdf" target="_blank" rel="noopener noreferrer">Bus network map</a></strong></p>       
        </Box>
    )


}
