
import React from 'react';

export class Iframe extends React.Component {
  render(){
     return(
         <div>
             <iframe src={this.props.src} title={"Google Maps Venue"} height={this.props.height} width={this.props.width}/> 
         </div>   
      )
   }
}