import { Box } from "@mui/system";



export default function About(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto", textAlign: "justify"}}>
            <h1><strong>Author information</strong></h1> 
            
            <h2 id="manuscriptPrep">
                Final Manuscript Preparation
            </h2> 
            <p>
            The final camera-ready manuscript of your paper should account for all the critical reviewers’ feedback.
            </p>

            <p>
            Please be advised that your manuscript should strictly comply with the very same paper template specified in the submission phase. Please note that the final papers are limited to either 3 (short paper) or 6 pages (regular paper), and you will not be able to pay over-length charges for the extra pages. The following steps are required for successful final submission:
            <ul>
                <li>
                    Camera Ready Copy (PDF) of your paper
                </li>
                
                <li>
                    Xplore compliance check PDF
                </li>
                
                <li>
                    Completed and signed IAP copyright form (PDF)

                </li>
            </ul>
            </p>
            
            <h4>
                Insertion of the IFIP Copyright Clearance Code Notice    
            </h4>

            <p>
                The appropriate copyright clearance code notice is to appear on the bottom of the first page of each paper.
            </p>

            <p>
            For LaTeX documents use:
            </p>
                
            <pre>{
            "\\usepackage[pscoord]{eso-pic} \n"+
            "\\newcommand{\\placetextbox}[3]{\n"+
            "    \\setbox0=\\hbox{#3}\n"+
            "    \\AddToShipoutPictureFG*{\n"+
            "        \\put(\\LenToUnit{#1\\paperwidth},\\LenToUnit{#2\\paperheight}){\n"+
            "            \\vtop{{\\null}\\makebox[0pt][c]{#3}}}\n"+
            "        }\n"+
            "}\n"+
            "\\placetextbox{.2}{0.055}{978-3-903176-62-1~\\copyright~2024 IFIP}"
            }
            </pre>

            <p>
            For Microsoft Word documents use:
            </p>
            <div>
            Microsoft Word users can use: ‘Insert’ {"->"} ‘Textbox’, insert the appropriate copyright notice in the textbox, and place the box (without border) at the bottom left on the first page.
            </div>

            <p>
            The output of your Copyright notice should look like this:
            </p>

            <p>
            978-3-903176-62-1 © 2024 IFIP
            </p>

            <p>
            For papers where ALL the authors are either employees of the US government, Crown governments or the European Union, the authors should contact the publication chairs to verify the copyright statement to use.
            </p>

            <h3>
            Further important requirements:
            </h3>

            <ul>
                <li>
                    The title is set in 24 pt non-bold
                </li>

                <li>Any running headers/footers or page-numbers must be removed</li>
                <li>Columns should be balanced, i.e. have the same length on each page</li>
                <li>Blue-underlining for URLs and email addresses should be removed</li>
                <li>Papers must be in US-Letter size. A4 or other sizes are not allowed</li>
            </ul>

            Please note that you should prepare the final manuscript with no page numbering.

            <h4>
            Validation of Your Manuscript by PDF eXpress
            </h4>


            <ul>
            <li>Go to <a href="https://ieee-pdf-express.org/account/login?ReturnUrl=%2F">IEEE PDF express Website.</a></li>
            <li>Create an IEEE PDF express account (use conference ID: 61578X).</li>
            <li>Use ‘new user’ if you haven’t used this site before.</li>
            </ul>

            <p>

            Use IEEE PDF eXpress to check if your PDF file is Xplore-compliant, OR, else, you can use this site to convert your source files into an IEEE Xplore-compliant PDF file (e.g., for LaTeX users, create a zip file that includes dvi and your eps figure files altogether, and then upload the zip file for the system to convert into a compliant PDF file; for MSWord users, upload the Word file and let it convert and return a compliant PDF file).
            </p>
            
            
            <h4>
                Submission of Your Manuscript to EDAS    
            </h4>

            IEEE PDF eXpress will send to your email an IEEE Xplore compliant version of your paper. You must then upload this version on EDAS (deadline 03 April 2024 23:59 CET). When uploading your paper on EDAS, please answer the question regarding permission to record and post your presentation.
            
            <h4>Submission of Your Manuscript Copyright Form</h4>

            <p>The corresponding author of each paper, acting on behalf of all of the authors of the paper, must complete and sign a Transfer of Copyright to IFIP form which you will receive pre-filled from the Publication Chair Farhad Arpanaei (farhad.arpanaei@uc3m.es).</p>

            <p>The corresponding author signing the copyright form must match the corresponding author marked on the paper. Please print, sign, and send the signed copy to the ONDM2024 Publication Co-Chair Alfonso Sanchez-Macian (alfonsan@it.uc3m.es) and TPC co-chair Jose A. Hernandez (jahgutie@it.uc3m.es).</p> 

            <p>Please do so no later than 04 April 23:59 CET as this step is compulsory for paper inclusion in the technical program and its submission for publication by IFIP Digital Library and IEEE Xplore@.</p>
            
            <h2 id="IEEEspecialIssue">
                IEEE/OSA JOCN Special Issue
            </h2> 

            <p>Selected ONDM 2024 papers will be considered for publication in the IEEE/OSA Journal of Optical Communications and Networking (JOCN) ONDM2024 Special Issue, to be published in Spring 2025. The selection criteria include the conference paper’s score, novelty and lemma. The extensibility of the paper with significant new material is also a key requirement for inclusion in this Special Issue. </p>
            
            <h2 id="callForPapers">
                Call for Papers
            </h2>
           
            <p>Following the path of previous editions, ONDM 2024 calls for contributions for cutting-edge research advances in optical network engineering spanning the user, control and/or management planes through the access, metro and core segments. The conference scope also includes the design and modelling of networks based on advanced photonic, packet transport and wireless technologies integrated with optical networks.</p>
            <p>ONDM 2024’s edition motto is: <strong>&ldquo;Optical networks at scale: coping with the scalability challenge of future smart networks &rdquo;</strong>.</p>
            <p>Although many visions regard the optical layer as an infinite bandwidth system, recent traffic reports by big network operators and service providers allow to forecast a full capacity drain of current commercial multiplexing, transmission and switching devices, making it necessary to resort to multi-fiber and multi-ROADM schemes in the short term.  This is especially the case of inter-data-center connectivity of global application service providers due to the rocketed communication needs of distributed computing by the latest AI and AR/VR systems. Service providers have recently reported unprecedented rates of up to 80-100% of annual growth in the last years, soon exceeding ports and wavelength routing capacity of ROADMs. The same challenge will be faced by telecom operators to cope with 6G&rsquo;s Tb/s user data rates envisioned for IMT-2030, raising scalability issues to both the IP and optical layers from access to core segments. Multi-band, spatial multiplexing -both multi-core and multi-fiber systems- relieve the situation at the price of a multiplicative increase of the complexity of the systems, as well as their management and control, in many cases, involving multiple stacked optical planes.</p>
            <p>This challenge calls for the development of radically novel optical network architectures and design views, based on different optical network disaggregation models, exploiting and integrating novel multidimensional photonic technologies as well as by adopting open and highly programmable hardware and software platforms such as software-defined networking (SDN) that need to deal with the new spatial dimensions. The objective is to achieve this target with improved scalability, efficiency, flexibility, availability, autonomy, resiliency and intelligence, while featuring real seamless integration of IP and optical layers.</p>
            <p>The scope of the conference includes, but is not limited to, the following topics, with a special request to include scalability analysis in the work where relevant:</p>

            <ul>  
            <li>Multiple optical planes vs single plane techno-economic analysis</li> 
            <li>Multi-core optical spatial division multiplexed (SDM) networks</li>
            <li>Multi-band optical switching and grooming</li>
            <li>Benchmarking the scalablity of network designs</li>
            <li>Zero-touch network and service management at scale</li>
            <li>Digital twin-based management and design</li>
            <li>Advances in optical network modelling and optimization</li>
            <li>Application of ML/AI in optical networks </li>
            <li>Routing and spectrum assignment in fixed and flex-grid flex-modulation optical networks</li>
            <li>Optical network availability, resilience, survivability, security and privacy</li>
            <li>Optical-packet transport</li>
            <li>Designing and optimizing large QKD networks</li>
            <li>Quantum Networks on the road to the Quantum Internet</li>
            <li>Optical networks exploiting photonic integrated circuits</li>
            <li>Visible light communications and networks</li>
            <li>Photonics-based Non-terrestial networks</li>
            <li>Free Space Optical communication</li>
            <li>Optical and wireless network convergence, including radio-over-fibre access networks</li>
            <li>Optical networks in 6G: backhaul, midhaul and fronthaul networking</li>
            <li>Novel and multidimensional optical network architectures</li>
            <li>Multi-mode/few-modes/multi-core optical spatial division multiplexed (SDM) networks</li>
            <li>Novel optical node designs including disaggregation and open optical line systems</li>
            <li>Intra-/inter-data centre connectivity for cloud/edge computing</li>
            <li>Optical network control, management and orchestration including SDN and NFV solutions</li>
            <li>Slicing, service chaining, virtualization and multi-tenancy techniques</li> 
            <li>Novel network telemetry and real-time monitoring technologies for optical networks</li>
            <li>Energy efficient and sustainable networks</li>
            <li>Impact of the hollow fibre revolution in network design</li>
            <li>Optical networking in support of vertical industries</li> 
            <li>Field trials and interoperability demonstrations</li>
            <li>Network function acceleration on Smart NICs</li>
            <li>In-network computing</li>
            </ul>
            
            <h2 id="submissionInstructions">
                Submission Instructions
            </h2>

            <p>Authors are encouraged to submit either abstract/short papers (3 pages) or full papers (up to 6 pages) in standard IEEE conference style (<a href="https://www.ieee.org/conferences/publishing/templates.html">https://www.ieee.org/conferences/publishing/templates.html</a>) describing original, unpublished research results, not currently under review by another conference or journal, addressing forefront research and development in the area of optical network design and modelling.</p>
            <p>Authors of 3-page papers must ensure their papers are formatted correctly, i.e., they are exactly 3 completely filled pages. Only PDF files with all fonts embedded are accepted. Your submitted PDF file and registered EDAS account of a paper must list the same author(s), title and abstract. Papers where the PDF and EDAS account do not match the author(s), title, and/or abstract will be withdrawn by the Technical Program Co-Chairs. Authors must submit their papers electronically through EDAS using the following link: <a href="https://edas.info/N31631">https://edas.info/N31631</a>.</p>
            <p>For more information or specific questions please e-mail us at: <u>ondm2024-chairs@edas.info</u> or at  <a href="https://edas.info/help.php?for=help&c=31631">https://edas.info/help.php?for=help&c=31631</a>.</p>
           
            <h2 id="bestPaperAward">
                Best Paper and Best Student Paper awards
            </h2>

            <p>The best paper will be awarded a monetary prize of 1000 euros and the best student paper, 500 euros. Only papers presented by first authors will be eligible for these prizes.
               Only papers with a student as the first author will be eligible for the student paper award. The best papers will be selected by the conference award scientific committee  
               based on the technical excellence and quality of the presentations. A decision on the best paper award will be made during the conference and announced on the last day.
            </p>

            <p> The best paper award rules <a href="/Rules_BPA_BSPA_ONDM_2024.pdf" download>here</a> </p>


            <h2 id="posters">
                Posters Information
            </h2>
            
            <p>
                All conference posters should be printed in <span><b><u>A0 Vertical (1189 mm High x 841 mm Wide)</u></b></span>. Please make sure that the posters are easy to understand and have a good readability.
            </p>

            <p>
            We offer a free service to authors to print their posters and transport them to the conference. If you are interested, please write <b>before April <s>24th</s> <span style={{color: "red"}}>Sunday 28th</span> </b> with the poster in pdf format to the following e-mail address:
            </p>
            <a href="mailto:gonzmart@pa.uc3m.es">gonzmart@pa.uc3m.es</a>

            {/*
            <h4>IEEE/OSA JOCN Special Issue</h4>

            Selected ONDM2024 papers will be considered for publication in the IEEE/OSA Journal of Optical Communications and Networking (JOCN) ONDM2024 Special Issue, to be published in Spring 2024. The selection criteria include the conference paper’s score, novelty, and impact.  The extensibility of the paper with significant new material is also a key requirement for inclusion in this Special Issue.
            <h4>Submission instructions</h4>


            <p>
            Authors are encouraged to submit either abstract / short papers (3 pages US letter size) or full papers (up to 6 pages US Letter size) in standard IEEE conference style (https://www.ieee.org/conferences/publishing/templates.html), describing original, unpublished research results, not currently under review by another conference or journal, addressing forefront research and development in the area of optical network design and modelling.
            </p>

            <p>
            Authors of 3-page papers must ensure their papers are formatted correctly, i.e., they are exactly 3 completely filled pages. 
            </p>

            <p>
            Only PDF files with all fonts embedded are accepted. Your submitted PDF file and registered EDAS account of a paper must list the same author(s), title and abstract. Papers where the PDF and EDAS account do not match the author(s), title, and/or abstract will be withdrawn by the Technical Program Co-Chairs. 
            </p>

            <p>
            Authors must submit their papers electronically through EDAS using the following link: https://edas.info/N29851. 
            </p>

            <p>
            Accepted papers are expected to be included in IEEE Xplore.
            </p>

            <h3>Call for Papers</h3>

            <p>

            Following the tradition of previous editions, ONDM 2023 will address cutting-edge research in optical networking in support of a wide variety of new services and applications. This includes the most recent trends such as: 5G and beyond; data-centre networking; Internet of Things; cloud/edge computing; content delivery networks; data analytics, network telemetry and real-time monitoring; autonomic networking; artificial intelligence / machine learning assisted networks; visible light communications; and Quantum Internet.
            Such trends drive the need for increased capacity, efficiency, flexibility adaptability and intelligence in the functions that optical networks must perform in the next years. In turn, these challenging needs can be addressed by developing new optical network architectures, based on different optical network disaggregation models, exploiting and integrating novel multidimensional photonic technologies as well as by adopting open and highly programmable hardware and software platforms such as software-defined networking (SDN) and network function virtualization (NFV). The ultimate goal of such network revolution is to enable new business models and opportunities for network operators and their customers. The scope of the conference includes but is not limited to the following topics related specifically to optical devices, systems, and networks:
            </p>

            <ul>
            <li>Advances in optical network modelling and optimization</li>
            <li>Routing and spectrum assignment in fixed and flex-grid optical networks</li>
            <li>Optical network availability, resilience, survivability, security and privacy</li>
            <li>Multi-layer (e.g., IP over optical) networking</li>
            <li>Quantum technologies and Quantum Internet</li>
            <li>Optical networks exploiting photonic integrated circuits</li>
            <li>Visible light communications and networks</li>
            <li>Optical and wireless network convergence, including radio-over-fibre access networks</li>
            <li>Optical networks in 5G and beyond: backhaul, midhaul and fronthaul networking</li>
            <li>Novel and multidimensional optical network architectures</li>
            <li>Multi-mode/few-modes/multi-core optical spatial division multiplexed (SDM) networks</li>
            <li>Novel optical node designs including disaggregation and open optical line systems</li>
            <li>Optical networks in support of intra-/inter-data centre connectivity and cloud/edge computing</li>
            <li>Optical network control, management and orchestration including SDN and NFV solutions</li>
            <li>Slicing, service chaining, virtualization and multi-tenancy techniques</li>
            <li>Orchestration and control of IT and network resources in optical data-centre networks</li>
            <li>Novel network telemetry and real-time monitoring technologies for optical networks</li>
            <li>Energy efficiency</li>
            <li>Impact of the hollow fibre revolution in network design</li>
            <li>Optical networking in support of vertical industries</li>
            <li>Field trials and interoperability demonstrations</li>
            <li>Techno-economic studies</li>
            <li>Zero-touch network and service management</li>
            <li>Network function acceleration</li>
            <li>In-network computing</li>
            </ul>

            <h3>
            Best Paper Award
            </h3>

            <p>
                The best paper award will be awarded a monetary prize of 1000 euros.  All submitted papers presented by first authors are subject to the best paper award.  The best paper will be selected by the conference award scientific committee.  The best paper will be selected based on the technical excellence and quality of the presentation. A decision on the best paper award will be made during the conference and announced on the last day.
            </p>

            <h3>
            Best Student Paper Award
            </h3>

            <p>
            The best student paper award will be awarded a monetary prize of 500 euros. Only papers with a student as the first author will be eligible for the competition.  The best student paper will be selected by the conference award scientific committee. The best student paper will be selected based on the technical excellence and quality of the presentation.  A decision on the best student paper award will be made during the conference and announced on the last day.
            </p>

            The best paper award rules can be found here.
 */}
        </Box>
    )


}
