import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Stack from '@mui/material/Stack';
import { pages } from '../utils/constants';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window, callback } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [value, setValue] = React.useState('home');

  const location  = useLocation();

  useEffect(() => {
    let locationName = location.pathname.split("/")[1]
    if(value !== locationName){
      setValue(locationName)
    }
  }, [location, value]);

  const handleChange = (event, newValue) => {
    if(event.target.className && event.target.className.baseVal === undefined && !event.target.className.includes("MuiBackdrop-root")){
      setValue(newValue);
      callback(newValue)
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        ONDM 2024
      </Typography>
      <Divider />
      <List>
        {pages.map((page) => {
          const baseElement = <ListItem key={page.url} disablePadding>
            <ListItemButton sx={{ textAlign: 'left', fontWeight: "900 !important" }} onClick={() => callback(page.url)}>
              <ListItemText primary={page.pageName} style={{color: "black"}} />
            </ListItemButton>
          </ListItem>

          if(!page.subpages) return baseElement

          const subItems = page.subpages.map(subpage => (
            <ListItem key={subpage.url} disablePadding>
            <ListItemButton sx={{ textAlign: 'left', fontWeight: "900 !important" }} onClick={() => callback(page.url + (subpage.urlSubpage === true ? "/" : "#") + subpage.url)}>
              <KeyboardArrowRightIcon/><ListItemText primary={subpage.pageName} style={{color: "black"}} />
            </ListItemButton>
          </ListItem>
          ))
          return (<>
            {baseElement}
            {subItems}
          </>)
        })}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>

      <Box onClick={handleDrawerToggle} sx={{display: { md: 'none' }}}>
      <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ ml: 2 }}
          >            
          <MenuIcon />
          </IconButton>
          Menu
      </Box>


    <Box sx={{ display: { md: 'flex', sm: 'none', xs: 'none' } }}>
          <TabContext value={value}>
          <Box sx={{ borderBottom: 0, borderColor: 'divider',  display: { xs:'none', sm: 'none', md: 'block' }, margin: "auto" }}>
            <TabList onChange={handleChange}>
              {pages.map(page =>{
                if(page.subpages){
                  return(
                    <Tab 
                    value={page.url}
                    label={<Stack alignItems="center" direction={"row"}>{page.pageName}<CustomMenu page={page} callback={callback}/></Stack>} style={{fontWeight: 900}}/>
                  )
                }else{
                  return(<Tab label={page.pageName} value={page.url} style={{fontWeight: 900}}/>)
                }
              })}
            </TabList>
          </Box>
        </TabContext>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
    </div>
  );
}


function CustomMenu({name, page, callback}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleEnter = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (e) => {

    setAnchorEl(null);
  };
  const handleMenuClick = (subpage) =>{
    setAnchorEl(null);
    callback(page.url + (subpage.urlSubpage === true ? "/" : "#") + subpage.url)

    if(!subpage.urlSubpage){
      setTimeout(() => {
        const releventDiv = document.getElementById(subpage.url);
        if(releventDiv)
        releventDiv.scrollIntoView({behavior: "smooth"});
      }, 100)
    }

  }

  if(!page){
    return("")
  }

  return (
    <div>
    {name}
    <Button sx={{minWidth: 0, px:0, zIndex: 8}} onMouseOver={handleEnter} >
    <KeyboardArrowDownIcon sx={{p: "0px"}}/>
    </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >

        {page.subpages.map(subpage => {
          return(
            <MenuItem onClick={(e) => {handleMenuClick(subpage); e.stopPropagation()}} disableRipple>
              {subpage.pageName}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  );
}

export default DrawerAppBar;