import { Box } from "@mui/system";



export default function Committees(){

    return(
        <Box sx={{maxWidth: "100%", margin:"auto"}}>
            <h1><strong>Committees</strong></h1>
            <h2 id="chairs">
                ONDM 2024 Chairs
            </h2>
            <h3>General Chair</h3>
            <p>
                David Larrabeiti-López, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <h3>TPC Co-Chairs</h3>
            <p>
                Luca Valcarenghi, Scuola Superiore Sant’Anna, Pisa, Italy
            </p>
            <p>
                Carmen Mas-Machuca, University of the Bundeswehr Munich (UniBW), Munich, Germany
            </p>
            <p>
                José A. Hernández-Gutiérrez, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <h3>Publication Chairs</h3>
            <p>
                Farhad Arpanaei, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <p>
                Boris Martinez Aguilar, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <p>
                Natalia Koneva, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <p>
                Gonzalo Martínez Ruiz de Arcaute, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <h3>Publicity Chair</h3>
            <p>
                Alfonso Sánchez-Macián, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <p>
                Gabriel Otero Pérez, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <h3>Web Chair</h3>
            <p>
                Francisco Caravaca Crespo, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <p>
                Juan Carlos Hernández-Hernández, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <h3>Finance Chair</h3>
            <p>
                Ángel Cuevas, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
            <h3>
                Local organization Chair
            </h3>
            <p>
                Alfonso Sánchez-Macián, University Carlos III de Madrid (UC3M), Madrid, Spain
            </p>
           
            <h2 id="steeringCommittee">
                ONDM Steering Committee
            </h2>
            <h3>Chair</h3>
            <p>
            Lena Wosinska, Chalmers University of Technology, Sweden
            </p>
            <h3>Members</h3>
            <p>Marco Ruffini, Trinity College Dublin, Ireland</p>
            <p>Anna Tzanakaki, National and Kapodistrian University of Athens, Greece</p>
            <p>Raul Muñoz, CTTC, Barcelona, Spain</p>
            <p>Paolo Monti, Chalmers University of Technology, Sweden</p>
            <p>Jarosław Turkiewicz, Warsaw University of Technology, Poland</p>
            <p>
            Teresa Gomes, University of Coimbra and INESC Coimbra, Portugal
            </p>
            <h2 id="tpcMembers">
                Technical Program Committee
            </h2>
            <p>Slavisa Aleksic, Hochschule fuer Telekommunikation Leipzig, Germany</p>
            <p>Achim Autenrieth, Adtran, Germany</p>
            <p>Alejandra Beghelli, University College London, United Kingdom&nbsp;</p>
            <p>Andrea Bianco, Politecnico di Torino, Italy&nbsp;</p>
            <p>Pierpaolo Boffi, Politecnico di Milano, Italy&nbsp;</p>
            <p>Luiz Bonani, Universidade Federal do ABC, Brazil&nbsp;</p>
            <p>Ramon Casellas, Centre Tecnol&ograve;gic de Telecomunicacions de Catalunya (CTTC/CERCA), Spain&nbsp;</p>
            <p>Piero Castoldi, Scuola Superiore Sant&apos;Anna, Italy&nbsp;</p>
            <p>Isabella Cerutti, Joint Research Centre, Italy&nbsp;</p>
            <p>Xiaoliang Chen, Sun Yat-Sen University, USA&nbsp;</p>
            <p>Konstantinos Christodoulopoulos, University of Athens, Greece&nbsp;</p>
            <p>Tibor Cinkler, Budapest University of Technology and Economics, Hungary&nbsp;</p>
            <p>Didier Colle, IMEC - Ghent University, Belgium&nbsp;</p>
            <p>David Coudert, Universit&eacute; C&ocirc;te d&apos;Azur, Inria, CNRS, I3S, France&nbsp;</p>
            <p>Filippo Cugini, CNIT, Italy&nbsp;</p>
            <p>Vittorio Curri, Politecnico di Torino, Italy&nbsp;</p>
            <p>Juliana de Santi, UTFPR - Federal University of Technology - Paran&aacute;, Brazil&nbsp;</p>
            <p>Amaro de Sousa, Institute of Telecommunications, University of Aveiro, Portugal&nbsp;</p>
            <p>Jose Manuel Delgado Mendinueta, University Carlos III de Madrid, Spain&nbsp;</p>
            <p>Gustavo Figueiredo, Federal University of Bahia, Brazil&nbsp;</p>
            <p>Bodhisattwa Gangopadhyay, Meta, United Kingdom&nbsp;</p>
            <p>Miquel Garrich, Universidad Polit&eacute;cnica de Cartagena, Spain&nbsp;</p>
            <p>Alberto Gatto, Politecnico di Milano, Italy&nbsp;</p>
            <p>Alessio Giorgetti, National Research Council of Italy, Italy&nbsp;</p>
            <p>Rita Girao-Silva, FCTUC, University of Coimbra, Portugal&nbsp;</p>
            <p>Róża Goścień, Wroclaw University of Science and Technology, Poland&nbsp;</p>
            <p>Helmut Griesser, ADVA Optical Networking SE, Germany&nbsp;</p>
            <p>Hiroshi Hasegawa, Nagoya University, Japan&nbsp;</p>
            <p>Emilio Hugues-Salas, British Telecom, United Kingdom&nbsp;</p>
            <p>Paola Iovanna, Ericsson, Italy&nbsp;</p>
            <p>Luisa Jorge, Research Centre in Digitalization and Intelligent Robotics (CeDRI), IPB, Bragan&ccedil;a, Portugal&nbsp;</p>
            <p>Wojciech Kabacinski, Poznan University of Technology, Poland&nbsp;</p>
            <p>Ezhan Karasan, Bilkent University, Turkey&nbsp;</p>
            <p>Daniel Kilper, Trinity College Dublin, Ireland&nbsp;</p>
            <p>Nattapong Kitsuwan, The University of Electro-Communications, Japan&nbsp;</p>
            <p>Mirosław Klinkowski, National Institute of Telecommunications, Poland&nbsp;</p>
            <p>Panagiotis Kokkinos, National Technical University of Athens, Greece&nbsp;</p>
            <p>Guido Maier, Politecnico di Milano, Italy&nbsp;</p>
            <p>Ricardo Martinez, Centre Tecnol&ograve;gic de Telecomunicacions de Catalunya (CTTC/CERCA), Spain&nbsp;</p>
            <p>L&uacute;cia Martins, University of Coimbra, Portugal&nbsp;</p> 
            <p>Xavi Masip-Bruin, Universitat Polit&egrave;cnica de Catalunya (UPC), Spain&nbsp;</p>
            <p>Francesco Matera, Fondazione Ugo Bordoni, Italy&nbsp;</p>
            <p>Paulo Melo, Universidade de Coimbra, Portugal&nbsp;</p>
            <p>Noem&iacute; Merayo, Universidad de Valladolid, Spain&nbsp;</p>
            <p>Paolo Monti, Chalmers University of Technology, Sweden&nbsp;</p>
            <p>Francesco Musumeci, Politecnico di Milano, Italy&nbsp;</p>
            <p>Antonio Napoli, Infinera, Germany&nbsp;</p>
            <p>Carlos Natalino, Chalmers University of Technology, Sweden&nbsp;</p>
            <p>Derek Nesset, Huawei Technologies, United Kingdom&nbsp;</p>
            <p>Wenda Ni, ByteDance Networking, USA&nbsp;</p>
            <p>Albert Pag&egrave;s, Universitat Polit&egrave;cnica de Catalunya (UPC), Spain&nbsp;</p>
            <p>Paola Parolari, Politecnico di Milano, Italy&nbsp;</p>
            <p>Jo&atilde;o Pires, Instituto de Telecomunica&ccedil;&otilde;es, Portugal&nbsp;</p>
            <p>Yvan Pointurier, Huawei, France&nbsp;</p>
            <p>Roberto Proietti, University of California, Davis, USA&nbsp;</p>
            <p>Carla Raffaelli, University of Bologna, Italy&nbsp;</p>
            <p>Chathurika Ranaweera, Deakin University, Australia&nbsp;</p>
            <p>Moises Ribeiro, Federal Universty of Espirito Santo, Brazil&nbsp;</p>
            <p>Ronald Romero Reyes, Technische Universit&auml;t Chemnitz, Germany&nbsp;</p>
            <p>Cristina Rottondi, Politecnico di Torino, Italy&nbsp;</p>
            <p>George Rouskas, North Carolina State University, USA&nbsp;</p>
            <p>Marc Ruiz Ramirez, Universitat Polit&egrave;cnica de Catalunya (UPC), Spain&nbsp;</p>
            <p>Dorabella Santos, INESC-Coimbra, Portugal&nbsp;</p>
            <p>Motoyoshi Sekiya, Fujitsu Limited, Japan&nbsp;</p>
            <p>Andrea Sgambelluri, Scuola Superiore Sant&apos;Anna Pisa, Italy&nbsp;</p>
            <p>Behnam Shariati, Fraunhofer HHI, Germany&nbsp;</p>
            <p>Nina Skorin-Kapov, Centro Universitario de Defensa, CUD San Javier, Spain&nbsp;</p>
            <p>Salvatore Spadaro, Universitat Politecnica de Catalunya (UPC), Spain&nbsp;</p>
            <p>Suresh Subramaniam, George Washington University, USA&nbsp;</p>
            <p>Federico Tonini, CNIT, Italy&nbsp;</p> 
            <p>Valerio Viscardi, none, Italy&nbsp;</p>
            <p>Vinod Vokkarane, University of Massachusetts Lowell, USA&nbsp;</p>
            <p>Krzysztof Walkowiak, Wroclaw University of Science and Technology, Poland&nbsp;</p>
            <p>Lena Wosinska, Chalmers University of Technology, Sweden&nbsp;</p>
            <p>Paul Wright, BT plc, United Kingdom&nbsp;</p>
            <p>Sugang Xu, National Institute of Information and Communications Technology, Japan&nbsp;</p>
            <p>Shuangyi Yan, University of Bristol, United Kingdom&nbsp;</p>
            <p>Xuelin Yang, Shanghai Jiao Tong University, China&nbsp;</p>
            <p>Qingcheng Zhu, Beijing University of Posts and Telecommunications, China&nbsp;</p>
            <p>Moshe Zukerman, City University of Hong Kong, Hong Kong</p>

        </Box>
    )


}
