export const pages = [
    {
        pageName: "Home",
        url: "home"
    },
    {
        pageName: "Author Info",
        url: "authorInfo",
        subpages: [
            {
                pageName: "Final Manuscript Preparation",
                url: "manuscriptPrep"
            },
            {
                pageName: "IEEE/OSA JOCN Special Issue",
                url: "IEEEspecialIssue"
            },           
            {
                pageName: "Call for Papers",
                url: "callForPapers"
            },
            {
                pageName: "Submission Instructions",
                url: "submissionInstructions"
            },
            {
                pageName: "Best Paper Award",
                url: "bestPaperAward"
            },
            {
                pageName: "Posters Information",
                url: "posters"
            }
        ]
    },
    {
        pageName: "Registration",
        url: "registration"
    },
    {
        pageName: "Programme",
        url: "program",
        subpages: [
            {
                pageName: "Technical Programme",
                url: "technical-program",
                urlSubpage: true
            },
            {
                pageName: "Keynotes",
                url: "keynotes",
                urlSubpage: true
            },
            {
                pageName: "Invited Speakers",
                url: "invited-speakers",
                urlSubpage: true
            },
            {
                pageName: "Tutorials",
                url: "tutorials",
                urlSubpage: true
            },
            {
                pageName: "Workshops",
                url: "workshops",
                urlSubpage: true
            }
        ]
    },
    {
        pageName: "Committees",
        url: "committees",
        subpages: [
            {
                pageName: "Chairs",
                url: "chairs"
            },
            {
                pageName: "Steering Committee",
                url: "steeringCommittee"
            },
            {
                pageName: "TPC Members",
                url: "tpcMembers"
            }
        ]
    },
    {
        pageName: "Become a Sponsor",
        url: "sponsor"
    },
    {
        pageName: "Travel Info",
        url: "travelInfo"
    },
    {
        pageName: "Venue",
        url: "venue"
    },
    {
        pageName: "Social Events",
        url: "socialEvents"
    },
    {
        pageName: "About",
        url: "about"
    }
]
